import Quill from 'quill/core';
import Toolbar from 'quill/modules/toolbar';
import Snow from 'quill/themes/snow';
import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Underline from 'quill/formats/underline';
import Strike from 'quill/formats/strike';
import List, {ListItem} from 'quill/formats/list';
import Link from 'quill/formats/link';
import MagicUrl from './quill.magic.url';

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

Link.sanitize = function(url) {
    // Force absulte URLs
    if (!url.match(/^(https?|\/\/|mailto:|tel:)/)) {
        return `http://${url}`;
    } else {
        return url;
    }
};

Quill.register({
    'modules/toolbar': Toolbar,
    'themes/snow': Snow,
    'formats/bold': Bold,
    'formats/italic': Italic,
    'formats/underline': Underline,
    'formats/strike': Strike,
    'formats/list': List,
    'formats/list-item': ListItem,
    'formats/link': Link,
    'modules/magicUrl': MagicUrl,
});

export default Quill;
