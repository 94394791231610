import TimeInputTmpl from './timeinput.tmpl.html';

export default  {
    bindings: {
        time: '=',
        time24h: '<',
        timeDisabled: '<',
    },
    template: TimeInputTmpl,
    controller: /* @ngInject */ class TimeInputComponent {
        constructor(moment) {
            this.moment = moment;
        }

        $onInit() {
            this.formattedTime = this.formatTime(this.time);
        }

        formatTime(time) {
            let duration = this.moment.duration(time, 'minutes');
            return this.moment.utc().startOf('day').add(duration).format(this.time24h? 'HH:mm': 'h:mm a');
        }

        parseTime(value) {
            let parsedTime = this.moment.utc(value, ['HH:mm', 'h:mm a', 'hmm a', 'Hmm']);
            if (parsedTime.isValid()) {
                let midnight = parsedTime.clone().startOf('day');
                 return parsedTime.diff(midnight, 'minutes');
            } else {
                return undefined;
            }
        }

        formattedTimeChanged(value) {
            this.time = this.parseTime(value);

            if (this.time !== undefined) {
                this.formattedTime = this.formatTime(this.time);
            } else {
                this.formattedTime = '';
            }
        }
    }
};
