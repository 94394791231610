import * as Sentry from '@sentry/browser';
import PlanSelectCtrl from './planselect.controller.js';
import PlanSelectTmpl from './planselect.tmpl.html';

export default /* @ngInject */ class AccountService {
    constructor($q, $mdDialog, KendoService, IntercomClientService, GoogleAnalyticsClientService, TeamCalClientService) {
        this.$q = $q;
        this.$mdDialog = $mdDialog;
        this.KendoService = KendoService;
        this.Intercom = IntercomClientService;
        this.GoogleAnalytics = GoogleAnalyticsClientService;
        this.TeamCalClientService = TeamCalClientService;
        this.account = {
            stripe: undefined,
            sso: undefined,
            isOrgAccount: undefined,
            features: [],
            availablePlans: [],
            restrictions: undefined,
            user: {
                loggedIn: false,
                id: undefined,
                createdOn: undefined,
                intercomId: undefined,
                fullName: undefined,
                email: undefined,
                isOwner: false,
                settings: undefined,
                notifications: new Set(),
            },
        };
    }

    _load(preventRedirect) {
        return this.TeamCalClientService.getAccount(preventRedirect).then(response => {
            this.account.stripeKey = response.data.stripeKey;
            this.account.sso = response.data.sso;
            this.account.isOrgAccount = response.data.isOrgAccount;
            this.account.features = response.data.features;
            this.account.availablePlans = response.data.availablePlans;

            this._set_user(response.data);
            this._set_user_settings(response.data.userSettings);
            this._set_restrictions(response.data.restrictions);

            return this.TeamCalClientService.getAccountNotifications().then(response => {
                this.account.user.notifications = new Set(response.data.notifications);
                return this.account;
            }, () => {
                return this.account;
            });
        }, () => {
            this.account.user.loggedIn = false;
            return this.account;
        });
    }

    _set_user(data) {
        let user = this.account.user;

        user.loggedIn = true;
        user.id = data.id;
        user.createdOn = data.createdOn;
        user.intercomId = data.intercomId;
        user.fullName = data.fullName;
        user.email = data.email;
        user.isOwner = data.isOwner;
        user.trialExpiration = data.trialExpiration;

        this._set_sentry(user);
        this._set_intercom(user);
        this._set_ga(user);
    }

    _set_restrictions(restrictions) {
        if (this.account.restrictions === undefined) {
            this.account.restrictions = {};
        }

        this.account.restrictions.add = restrictions.add;
        this.account.restrictions.edit = restrictions.edit;
        this.account.restrictions.move = restrictions.move;
        this.account.restrictions.delete = restrictions.delete;
    }

    _set_user_settings(userSettings) {
        let user = this.account.user;

        if (user.settings === undefined) {
            user.settings = {};
        }

        user.settings.timezone = userSettings.timezone;
        user.settings.time24h = userSettings.time24h;
        user.settings.dateOrder = userSettings.dateOrder;
        user.settings.weekStart = userSettings.weekStart;
        user.settings.showWeeks = userSettings.showWeeks;
        user.settings.schedulesViewMode = userSettings.schedulesViewMode;

        this.KendoService.setCulture(
            user.settings.weekStart,
            user.settings.time24h,
            user.settings.dateOrder
        );
    }

    _set_sentry(user) {
        Sentry.configureScope((scope) => {
          scope.setUser({
              id: user.id,
              email: user.email,
          });
        });
    }

    _set_intercom(user) {
        this.Intercom.init(
            user.id,
            user.intercomId,
            user.createdOn,
            user.fullName,
            user.email,
            user.isOwner,
            this.hasFeature('trial'),
            user.trialExpiration
        );
    }

    _set_ga(user) {
        this.GoogleAnalytics.init(user.id);
    }

    get(refresh=false, preventRedirect=false) {
        if (this.account.user.settings === undefined || refresh) {
            return this._load(preventRedirect);
        } else {
            let deferred = this.$q.defer();
            deferred.resolve(this.account);
            return deferred.promise;
        }
    }

    trialExpiration() {
        return this.account.user.trialExpiration;
    }

    hasFeatures() {
        return this.account.features.length !== 0;
    }

    hasFeature(name) {
        for(let feature of this.account.features) {
            if (name === feature) {
                return true;
            }
        }

        return false;
    }

    updateTimezone(timezone) {
        this.account.user.settings.timezone = timezone;
        this.KendoService.setCulture(
            this.account.user.settings.weekStart,
            this.account.user.settings.time24h,
            this.account.user.settings.dateOrder
        );

        this.TeamCalClientService.updateTimezone(timezone);
    }

    updateTime24h(enabled) {
        this.account.user.settings.time24h = enabled;
        this.KendoService.setCulture(
            this.account.user.settings.weekStart,
            this.account.user.settings.time24h,
            this.account.user.settings.dateOrder
        );

        this.TeamCalClientService.updateTime24h(enabled);
    }

    updateDateOrder(order) {
        this.account.user.settings.dateOrder = order;
        this.KendoService.setCulture(
            this.account.user.settings.weekStart,
            this.account.user.settings.time24h,
            this.account.user.settings.dateOrder
        );

        this.TeamCalClientService.updateDateOrder(order);
    }

    updateWeekStart(weekStart) {
        this.account.user.settings.weekStart = weekStart;
        this.KendoService.setCulture(
            this.account.user.settings.weekStart,
            this.account.user.settings.time24h,
            this.account.user.settings.dateOrder
        );

        this.TeamCalClientService.updateWeekStart(weekStart);
    }

    updateShowWeeks(showWeeks) {
        this.account.user.settings.showWeeks = showWeeks;
        this.TeamCalClientService.updateShowWeeks(showWeeks);
    }

    updateSchedulesViewMode(mode) {
        this.account.user.settings.schedulesViewMode = mode;
        this.TeamCalClientService.updateSchedulesViewMode(mode);
    }

    setRestrictions(add, edit, move, del) {
        let restrictions = this.account.restrictions;
        restrictions.add = add;
        restrictions.edit = edit;
        restrictions.move = move;
        restrictions.delete = del;

        this.TeamCalClientService.setRestrictions(add, edit, move, del);
    }

    setNotification(notification) {
        this.account.user.notifications.add(notification);
        this.TeamCalClientService.setAccountNotification(notification);
    }

    selectPlan($event, mode, currentPlan, currentInterval) {
        return this.$mdDialog.show({
            controller: PlanSelectCtrl,
            controllerAs: 'ctrl',
            template: PlanSelectTmpl,
            parent: angular.element(document.body),
            targetEvent: $event,
            clickOutsideToClose: true,
            locals: {
                mode: mode,
                stripeKey: this.account.stripeKey,
                availablePlans: this.account.availablePlans,
                currentPlan: currentPlan,
                currentInterval: currentInterval,
                user: this.account.user,
            }
        });
    }
}
