import RowFilterTmpl from './rowfilter.tmpl.html';

export default  {
    bindings: {
        scheduler: '<',
        filterChangedEvent: '&',
    },
    template: RowFilterTmpl,
    controller: /* @ngInject */ class RowFilterComponent {
        constructor() {
        }

        $onInit() {
            this.search = '';
            this.rows = this.scheduler.getRows();
        }

        selectedChanged(row) {
            this.filterChangedEvent({rows: this.rows, row: row});
        }

        showAllClicked() {
            this.search = '';
            this.rows.forEach(function(row) {
                row.visible = true;
            });

            this.filterChangedEvent({rows: this.rows});
        }

        hideAllClicked() {
            this.search = '';
            this.rows.forEach(function(row) {
                row.visible = false;
            });

            this.filterChangedEvent({rows: this.rows});
        }
    }
};
