import EventSearchTmpl from './eventsearch.tmpl.html';

export default  {
    bindings: {
        scheduleId: '<',
        scheduler: '<',
        events: '=',
        jumpToEvent: '&',
        eventSelected: '&',
    },
    template: EventSearchTmpl,
    controller: /* @ngInject */ class EventSearchComponent {
        constructor(moment, TeamCalClientService) {
            this.moment = moment;
            this.TeamCalClientService = TeamCalClientService;
        }

        $onInit() {
            this.searchText = '';
            this.isSearching = 0;
            this.rows = this.scheduler.getRows();
            if (!this.events) {
                this.events = [];
            }
        }

        keyPressed($event) {
            if ($event.keyCode === 13) {
                this.search(this.searchText);
            }
        }

        searchClicked() {
            this.search(this.searchText);
        }

        search(text) {
            this.events.splice(0, this.events.length);  // clear search results
            this.isSearching = 0;
            const from = this.moment().subtract(5, 'years'); // Only go back 5 years
            const to = this.moment().add(3, 'years'); // Only look forward for 3 years
            const limit = 250;

            this.rows.forEach(row => {
                this.isSearching += 1;
                this.TeamCalClientService.searchEvents(this.scheduleId, row.id, from, to, text, limit).then(response => {
                    const events = response.data;
                    events.forEach(event => { event.row = {id: row.id, name: row.name}; } );

                    this.events.push(...events);
                }).finally(() => { this.isSearching = Math.max(0, this.isSearching - 1); });
            });
        }

        jumpToEventClicked(event) {
            this.jumpToEvent({row: event.row, event: event});
        }

        eventClicked(event) {
            this.eventSelected({row: event.row, event: event});
        }
    }
};
