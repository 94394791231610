import PickerTmpl from './emojipicker.tmpl.html';
import { createPopup } from '@picmo/popup-picker';

export default  {
    bindings: {
        pickedFn: '&',
    },
    template: PickerTmpl,
    controller: /* @ngInject */ class EmojiPickerComponent {
        constructor($timeout) {
            this.$timeout = $timeout;
        }

        $onInit() {
            this.picker = createPopup({
                autoFocus: 'search',
                showPreview: false,
            }, {
                onPositionLost: `destroy`,
                showCloseButton: false,
                position: 'bottom-start',
                className: 'emoji-picker-popup-container',
            });

            this.picker.addEventListener('emoji:select', (event) => {
                this.$timeout(() => {
                    this.pickedFn({'$event': event});
                }, 0);
            });
        }

        pickEmojiClicked($event) {
            const trigger = $event.currentTarget;
            this.picker.open({
                triggerElement: trigger,
                referenceElement: trigger,
            });
        }
    }
};
