(function() {
    /*
        Improve KendoUI Performance:
        - Suppress duplicate layouting and render functions
        - See also TimelineViews.Service.js
     */
    const patchedScheduler = kendo.ui.Scheduler.extend({
        init: function(element, options) {
            kendo.ui.Scheduler.fn.init.call(this, element, options);
        },
        options: {
             name: "PatchedScheduler",
        },
        _progress: function() {
            // Disable progress functionality - it triggers Browser re-layouting and is slow and not required.
            // Do nothing
        },
        refresh: function(e) {
            // Do not call refreshLayout() as it's already called inside the render() function
            const view = this.view();
            if (view) {
                if (view.setPatchOnce) view.setPatchOnce();
                kendo.ui.Scheduler.fn.refresh.call(this, e);
            }
        }
    });

    kendo.ui.plugin(patchedScheduler);
})();
