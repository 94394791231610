import angular from 'angular';

import AddRowCtrl from './addrow.controller.js';
import AddRowTmpl from './addrow.tmpl.html';
import SettingsCtrl from './settings.controller.js';
import SettingsTmpl from './settings.tmpl.html';
import EventEditCtrl from './eventedit.controller.js';
import EventEditTmpl from './eventedit.tmpl.html';
import EventDeleteCtrl from './eventdelete.controller.js';
import EventDeleteTmpl from './eventdelete.tmpl.html';
import ShareScheduleCtrl from './share.controller.js';
import ShareScheduleTmpl from './share.tmpl.html';

export default /* @ngInject */ class ScheduleCtrl {
    constructor($state, $transition$, $mdDialog, $mdMenu, $mdToast, $timeout, moment, IntercomClientService,
                TeamCalClientService, ViewScaleService, ColorSelectorService, LocalStorageService,
                AccountService, IntroService, account) {
        this.$state = $state;
        this.$mdDialog = $mdDialog;
        this.$mdMenu = $mdMenu;
        this.$mdToast = $mdToast;
        this.$timeout = $timeout;
        this.moment = moment;
        this.Intercom = IntercomClientService;
        this.TeamCalClientService = TeamCalClientService;
        this.ViewScaleService = ViewScaleService;
        this.Storage = LocalStorageService;
        this.ColorSelectorService = ColorSelectorService;
        this.AccountService = AccountService;
        this.IntroService = IntroService;
        this.account = account;
        this.id = $transition$.params().id;
        this.name = '';
        this.showIntro = false;
        this.trialExpiration = moment(AccountService.trialExpiration()).add(1, 'days'); // End expiration at day 1, not 0.
        this.isTrialExpired = !AccountService.hasFeatures();
        this.isTrial = AccountService.hasFeature('trial');
        this.disableExport = !AccountService.hasFeature('export');
        this.showSharing = AccountService.hasFeature('sharing');
        // Disable sharing button for business accounts which don't want to have sharing enabled
        this.disableSharing = AccountService.hasFeature('restrictions') & !this.showSharing;
        this.isRefreshing = false;
        this.rowFilterActive = false;
        this.eventSearchCache = [];
        this.moveRow = {
            active: false,
            id: undefined,
            name: ''
        };

        this.eventFilterStorageKey = () => { return `eventFilter:${this.id}`; };
        this.rowFilterStorageKey = () => { return `rowFilter:${this.id}`; };

        this.settings = {
            view: 'd',
            currentDate: this.parseDateFromUrl($transition$.params().date).toDate(),
            mode: 'n',
            eventFilter: '',
            showEventFilter: false,
            workWeekStart: 1,
            workWeekEnd: 5,
            workDayStart: 1,
            workDayEnd: 5,
            timezone: undefined,
            showWorkHours: false,
            showWeekends: true,
            allDaySettings: undefined,
            outOfOfficeSettings: undefined,
            moveAction: 'M',
        };
    }

    setPageTitle() {
        const defaultPageTitle = this.$state.get('base').data.defaultPageTitle;
        if (this.name) {
            document.title = `${this.name} - ${defaultPageTitle}`;
        } else {
            document.title = defaultPageTitle;
        }
    }

    schedulerInitialized() {
        this.$timeout(() => {
            this.loadSchedule().then(() => {
                this.$timeout(() => {
                    this.eventFilterRestore();
                    this.rowFilterRestore();
                    this.refreshScheduler(true, true);
                });
                this.Intercom.sendEvent('schedule-open', {'id': this.id, 'name': this.name});
            });
        });
    }

    parseDateFromUrl(urlParamValue) {
        return urlParamValue? this.moment(urlParamValue): this.moment();
    }

    upgradeTeamCalClicked() {
        this.$state.go('base.account', { upgrade: true });
    }

    showSchedulesClicked() {
        this.$state.go('base.schedules');
    }

    showAccountClicked() {
        this.$state.go('base.account');
    }

    currentDateChanged() {
        this.$mdMenu.hide();
        this.refreshScheduler(true);
    }

    changeViewClicked(view) {
        this.settings.view = view;
        this.refreshScheduler(true);
        this.saveScheduleUserOptions();
    }

    goToTodayClicked() {
        this.settings.currentDate = this.moment().toDate();
        this.refreshScheduler(true);
    }

    nextDateClicked() {
        this.moveDate(1);
    }

    previousDateClicked() {
        this.moveDate(-1);
    }

    moveDate(val) {
        let date = this.moment(this.settings.currentDate);
        if (this.settings.view.indexOf('w') >= 0) {
            this.settings.currentDate = date.add(val, 'weeks').toDate();
        } else if (this.settings.view === 'd') {
            this.settings.currentDate = date.add(val, 'day').toDate();
        } else if (this.settings.view === 'm' || this.settings.view === '3m') {
            this.settings.currentDate = date.add(val, 'weeks').toDate();
        } else if (this.settings.view === 'y') {
            this.settings.currentDate = date.add(val, 'month').toDate();
        }

        this.refreshScheduler(true);
    }

    toggleWorkHoursClicked() {
        this.settings.showWorkHours = !this.settings.showWorkHours;
        this.refreshScheduler(false);
        this.saveScheduleUserOptions();
    }

    toggleShowWeekendsClicked() {
        this.settings.showWeekends = !this.settings.showWeekends;
        this.refreshScheduler(false);
        this.saveScheduleUserOptions();
    }

    toggleAllDayOnlyClicked() {
        if (this.settings.allDaySettings === 'only') {
            this.settings.allDaySettings = undefined;
        } else {
            this.settings.allDaySettings = 'only';
        }

        this.eventFilterRestore();
        this.saveScheduleUserOptions();
    }

    toggleHideAllDayClicked() {
        if (this.settings.allDaySettings === 'hide') {
            this.settings.allDaySettings = undefined;
        } else {
            this.settings.allDaySettings = 'hide';
        }

        this.eventFilterRestore();
        this.saveScheduleUserOptions();
    }

    toggleOutOfOfficeOnlyClicked() {
        if (this.settings.outOfOfficeSettings === 'only') {
            this.settings.outOfOfficeSettings = undefined;
        } else {
            this.settings.outOfOfficeSettings = 'only';
        }

        this.eventFilterRestore();
        this.saveScheduleUserOptions();
    }

    toggleHideOutOfOfficeClicked() {
        if (this.settings.outOfOfficeSettings === 'hide') {
            this.settings.outOfOfficeSettings = undefined;
        } else {
            this.settings.outOfOfficeSettings = 'hide';
        }

        this.eventFilterRestore();
        this.saveScheduleUserOptions();
    }

    viewModeClicked(mode) {
        this.settings.mode = mode;
        this.refreshScheduler(false);
        this.saveScheduleUserOptions();
    }

    refreshDataClicked() {
        this.refreshScheduler(true, true);
    }

    exportPdfClicked() {
        this.$mdToast.showSimple(`Generating PDF file...`);
        let name = this.name || 'export';
        this.scheduler.generatePdf(name);
    }

    exportExcelClicked() {
        this.$mdToast.showSimple(`Generating Excel file...`);
        let name = this.name || 'export';
        this.scheduler.generateExcel(name);
    }

    scheduleNameChanged(newName) {
        if (angular.isDefined(newName)) {
            this.name = newName;
            this.TeamCalClientService.updateScheduleName(this.id, this.name);
        } else {
            return this.name;
        }

        this.setPageTitle();
    }

    jumpToEventTriggered(row, event) {
        this.settings.currentDate = this.moment(event.start).toDate();
        this.refreshScheduler(true);
    }

    searchEventSelectTriggered(row, event) {
        if (!this.scheduler.getEvent(event.id)) {
            this.scheduler.addEventFromSearchEvent(row.id, event);
        } else {
            this.scheduler.editEvent(event.id);
        }
    }

    toggleEventFilterClicked() {
        this.settings.showEventFilter = !this.settings.showEventFilter;
        this.eventFilterChanged('');
    }

    eventFilterKeyPressed($event) {
        if ($event.keyCode == 27) {
            this.toggleEventFilterClicked();
        }
    }

    eventFilterChanged(value) {
        if (angular.isDefined(value)) {
            this.settings.eventFilter = value;
            let namesToFilter = value.
                replace(/,/g, ' ').
                replace(/;/g, ' ').
                replace(/ {2}/g, ' ').
                split(' ').
                filter((value) => value !== '');

            this.scheduler.filterEvents(namesToFilter, this.settings.allDaySettings, this.settings.outOfOfficeSettings);
            this.Storage.set(this.eventFilterStorageKey(), namesToFilter);
        }

        if (!value) {
            this.Storage.delete(this.eventFilterStorageKey());
        }
    }

    eventFilterRestore() {
        let storedEventFilterValue = this.Storage.get(this.eventFilterStorageKey());
        if (storedEventFilterValue) {
            this.settings.showEventFilter = true;
            this.eventFilterChanged(storedEventFilterValue);
        } else {
            this.scheduler.filterEvents([], this.settings.allDaySettings, this.settings.outOfOfficeSettings);
        }
    }

    rowFilterChanged(rows) {
        this.rowFilterActive = rows.filter(row => !row.visible).length > 0;
        let visibleRowIds = rows.filter(row => row.visible).map(row => row.id);
        this.scheduler.setRowVisibility(visibleRowIds);

        if (this.rowFilterActive) {
            this.Storage.set(this.rowFilterStorageKey(), visibleRowIds.join(','));
        } else {
            this.Storage.delete(this.rowFilterStorageKey());
        }
    }

    rowFilterRestore() {
        let storedRowFilterValue = this.Storage.get(this.rowFilterStorageKey());
        if (storedRowFilterValue) {
            let visibleRowIds = storedRowFilterValue.split(',');
            if (visibleRowIds.length > 0) {
                this.rowFilterActive = true;
                this.scheduler.setRowVisibility(visibleRowIds);
            }
        }
    }

    showAddRowModal($event) {
        this.$mdDialog.show({
            controller: AddRowCtrl,
            controllerAs: 'ctrl',
            template: AddRowTmpl,
            parent: angular.element(document.body),
            targetEvent: $event,
            clickOutsideToClose: true,
            locals: {
                existingRows: this.scheduler.getRows(),
                addRowFn: (calendar) => {
                    this.showIntro = false;
                    return this.addRow(calendar.name, calendar.type, calendar.id).then(() => {
                        this.refreshScheduler(false);
                        this.Intercom.sendEvent('schedule-add-row', {'id': this.id, 'name': this.name, 'row': calendar.name});
                        return true;
                    }, response => {
                        if (response.status === 400) {
                            if (response.data.code === 'CalendarNotFound') {
                                this.$mdToast.showSimple(`Calendar "${calendar.name}" does not exist`);
                            } else if (response.data.code === 'ScheduleRowAlreadyAdded') {
                                this.$mdToast.showSimple(`Calendar "${calendar.name}" already added`);
                                return true;
                            } else if (response.data.code === 'ScheduleRowCountExceeded') {
                                this.handleRowAddUpgradeNeeded(response.data.description);
                            }
                        }

                        return false;
                    });
                }
            }
        }).finally(() => {
            this.IntroService.show();
        });

        this.Intercom.sendEvent('schedule-add-row-open', {'id': this.id});
    }

    handleRowAddUpgradeNeeded(maxRowCount) {
        let dialog = this.$mdDialog.confirm()
            .title("Add calendar")
            .textContent(`Your subscription supports ${maxRowCount} calendars. You exceeded this limit. Please upgrade your account to add more.`)
            .ok('Upgrade')
            .cancel('Close');

        this.$mdDialog.show(dialog).then(() => {
            this.upgradeTeamCalClicked();
        });
    }

    showUpdateRowModal($event, rowId) {
        let row = this.scheduler.getRow(rowId);
        if (!row) {
            return;
        }

        let updateDialog = this.$mdDialog.prompt()
            .title('Rename')
            .textContent('Give this row a new name')
            .placeholder('Name')
            .initialValue(row.name)
            .ok('Rename')
            .cancel('Cancel')
            .targetEvent($event);

        this.$mdDialog.show(updateDialog).then((name) => {
            this.updateRow(row.id, name);
        });
    }

    contextMenuClicked($event, menu, actionType) {
        if (kendo.support.mobileOS) {
            // Do not show context menu on mobile
            return;
        }

        if ($($event.item).is('li')) {
            // Is sub menu open event
            return;
        }

        menu.remove('.k-separator');
        menu.remove('.ctx-up-row');
        menu.remove('.ctx-move-start-row');
        menu.remove('.ctx-down-row');
        menu.remove('.ctx-move-insert-row');
        menu.remove('.ctx-move-cancel-row');
        menu.remove('.ctx-hide-row');
        menu.remove('.ctx-rename-row');
        menu.remove('.ctx-delete-row');
        menu.remove('.ctx-color-row');
        menu.remove('.ctx-add-event');
        menu.remove('.ctx-edit-event');
        menu.remove('.ctx-duplicate-event');
        menu.remove('.ctx-delete-event');

        if (actionType === 'RowHeader') {
            const rowId = this.scheduler.getRowIdFromJsEvent($event);
            const row = this.scheduler.getRow(rowId);
            const rowColor = row.color || '#000000';

            const enableMoveItem = !this.rowFilterActive;
            const colorHtml = this.ColorSelectorService.getRowColorChooserHtml(color => this.contextRowMenuColorSelected(menu, rowId, color));

            if (this.moveRow.active) {
                menu.append([
                    {text: `Move "${this.moveRow.name}" here`, cssClass: 'ctx-move-insert-row md-subhead', enabled: enableMoveItem},
                    {text: 'Cancel move', cssClass: 'ctx-move-cancel-row md-subhead', enabled: enableMoveItem},
                ]);
            } else {
                menu.append([
                    {text: '↑ Move up', cssClass: 'ctx-up-row md-subhead', enabled: enableMoveItem},
                    {text: '→ Move before...', cssClass: 'ctx-move-start-row md-subhead', enabled: enableMoveItem},
                    {text: '↓ Move down', cssClass: 'ctx-down-row md-subhead', enabled: enableMoveItem},
                ]);
            }

            menu.append([
                {text: '', cssClass: 'k-separator'},
                {text: 'Hide', cssClass: 'ctx-hide-row md-subhead'},
                {text: 'Rename', cssClass: 'ctx-rename-row md-subhead'},
                {
                    text: `<div style="background-color:${rowColor};display:inline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>`,
                    encoded: false,
                    cssClass: 'ctx-color-row md-subhead',
                    content: `<div class="ctx-color-child-row">${colorHtml}</div>`
                },
                {text: '', cssClass: 'k-separator'},
                {text: 'Delete row', cssClass: 'ctx-delete-row md-subhead'}
            ]);
        } else if (actionType === 'Row' && !this.account.restrictions.add) {
            menu.append([
                {text: 'Add event', cssClass: 'ctx-add-event md-subhead'}
            ]);
        } else if (actionType === 'Event' && !this.account.restrictions.edit) {
            const eventId = this.scheduler.getEventIdFromJsEvent($event);
            const event = this.scheduler.getEvent(eventId);

            if (this.scheduler.isRowWriteable(event.resourceId)) {
                const row = this.scheduler.getRow(event.resourceId);
                const rowColor = row.color || '#000000';

                const colorHtml = this.ColorSelectorService.getEventColorChooserHtml(rowColor,color => this.contextEventMenuColorSelected(menu, event.resourceId, eventId, color));
                menu.append([
                    {text: 'Edit', cssClass: 'ctx-edit-event md-subhead'},
                    {text: 'Duplicate', cssClass: 'ctx-duplicate-event md-subhead'},
                    {
                        text: `<div style="background-color:${event.bgColor ? event.bgColor: rowColor};display:inline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>`,
                        encoded: false,
                        cssClass: 'ctx-color-row md-subhead',
                        content: `<div class="ctx-color-child-row">${colorHtml}</div>`
                    },
                    //{text: 'Delete event', cssClass: 'ctx-delete-event md-subhead'}
                ]);
            } else {
                menu.append([
                    {text: 'View', cssClass: 'ctx-edit-event md-subhead'},
                    {text: 'Duplicate', cssClass: 'ctx-duplicate-event md-subhead'},
                ]);
            }
        } else {
            $event.preventDefault();
        }
    }

    contextMenuSelectClicked($event) {
        let menuItem = angular.element($event.item);

        if (menuItem.hasClass('ctx-delete-row')) {
            const rowId = this.scheduler.getRowIdFromJsEvent($event);
            this.TeamCalClientService.deleteRow(
                this.id,
                rowId
            ).then(() => {
                this.scheduler.removeRow(rowId);
            });
        } else if (menuItem.hasClass('ctx-rename-row')) {
            const rowId = this.scheduler.getRowIdFromJsEvent($event);
            this.showUpdateRowModal($event, rowId);
        } else if (menuItem.hasClass('ctx-hide-row')) {
            this.rowFilterActive = true;
            const rowId = this.scheduler.getRowIdFromJsEvent($event);
            this.scheduler.hideRow(rowId);
        } else if (menuItem.hasClass('ctx-up-row')) {
            const rowId = this.scheduler.getRowIdFromJsEvent($event);
            this.moveRowClicked(rowId, 'up');
        } else if (menuItem.hasClass('ctx-move-start-row')) {
            this.moveRow.id = this.scheduler.getRowIdFromJsEvent($event);
            const row = this.scheduler.getRow(this.moveRow.id);
            if (row) {
                this.moveRow.active = true;
                this.moveRow.name = row.name;
                this.$mdToast.showSimple(`Move "${row.name}" by clicking on another row`);
            }
        } else if (menuItem.hasClass('ctx-down-row')) {
            const rowId = this.scheduler.getRowIdFromJsEvent($event);
            this.moveRowClicked(rowId, 'down');
        } else if (menuItem.hasClass('ctx-move-insert-row')) {
            const rowId = this.scheduler.getRowIdFromJsEvent($event);
            this.moveRowClicked(this.moveRow.id, rowId);
            this.moveRow.active = false;
        } else if (menuItem.hasClass('ctx-move-cancel-row')) {
            this.moveRow.active = false;
        } else if (menuItem.hasClass('ctx-add-event')) {
            this.scheduler.addEventFromJsEvent($event);
        } else if (menuItem.hasClass('ctx-edit-event')) {
            const eventId = this.scheduler.getEventIdFromJsEvent($event);
            if (eventId) {
                this.scheduler.editEvent(eventId);
            }
        } else if (menuItem.hasClass('ctx-duplicate-event')) {
            const eventId = this.scheduler.getEventIdFromJsEvent($event);
            if (eventId) {
                const event = this.scheduler.getEvent(eventId);
                document.body.style.cursor = 'wait';

                this.TeamCalClientService.getEventDescription(this.id, event.resourceId, event.id).then(response => {
                    document.body.style.cursor = null;
                    this.scheduler.duplicateEvent(eventId, response.data.description);
                }, () => {
                    document.body.style.cursor = null;
                    this.scheduler.duplicateEvent(eventId);
                });
            }
        } else if (menuItem.hasClass('ctx-delete-event')) {
            const eventId = this.scheduler.getEventIdFromJsEvent($event);
            if (eventId) {
                this.scheduler.deleteEvent(eventId, true);
            }
        }
    }

    contextRowMenuColorSelected(menu, rowId, color) {
        menu.close();

        return this.TeamCalClientService.updateRow(this.id, rowId, undefined, color).then((response) => {
            const row = response.data;
            this.scheduler.updateRowColor(row.id, row.bgColor);
        });
    }

    contextEventMenuColorSelected(menu, rowId, eventId, color) {
        menu.close();

        return this.TeamCalClientService.updateEventColor(this.id, rowId, eventId, color).then((response) => {
            const event = response.data;
            this.scheduler.updateEventColor(event.id, event.bgColor);
        });
    }

    showSettingsModal($event) {
        this.$mdDialog.show({
            controller: SettingsCtrl,
            controllerAs: 'ctrl',
            template: SettingsTmpl,
            parent: angular.element(document.body),
            targetEvent: $event,
            clickOutsideToClose: true,
            locals: {
                initSettings: this.settings,
                accountSettings: this.account.user.settings,
                showAdvancedSettings: this.AccountService.hasFeature('advanced_settings'),
            }
        }).then(settings => {
            let refreshData = this.settings.timezone !== settings.timezone;
            this.settings.timezone = settings.timezone;
            this.settings.workWeekStart = settings.workWeekStart;
            this.settings.workWeekEnd = settings.workWeekEnd;
            this.settings.workDayStart = settings.workDayStart;
            this.settings.workDayEnd = settings.workDayEnd;

            if (this.AccountService.hasFeature('advanced_settings')) {
                this.settings.moveAction = settings.moveAction;
            }

            this.saveScheduleOptions().then(() => {
                this.refreshScheduler(refreshData, refreshData);
            });
        });
    }

    addEventClicked() {
        this.scheduler.addEvent();
    }

    editEventClicked(event, save) {
        this.$mdDialog.show({
            controller: EventEditCtrl,
            controllerAs: 'ctrl',
            template: EventEditTmpl,
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            locals: {
                accountSettings: this.account.user.settings,
                accountRestrictions: this.account.restrictions,
                scheduleId: this.id,
                rows: event.readOnly ? undefined: this.scheduler.getRows(),
                event: event,
            }
        }).then((response) => {
            if (response.action == 'save') {
                save(response.event);
            } else if (response.action == 'delete') {
                this.scheduler.deleteEvent(event.id, true);
            }
        });
    }

    deleteEventClicked(event) {
        if (this.account.user.notifications.has('delete_event')) {
            this.scheduler.deleteEvent(event.id, false);
        } else {
            this.$mdDialog.show({
                controller: EventDeleteCtrl,
                controllerAs: 'ctrl',
                template: EventDeleteTmpl,
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                locals: {
                    accountService: this.AccountService,
                    account: this.account,
                    event: event,
                }
            }).then(() => {
                this.scheduler.deleteEvent(event.id, false);
            });
        }
    }

    shareClicked($event) {
        this.$mdDialog.show({
            controller: ShareScheduleCtrl,
            controllerAs: 'ctrl',
            template: ShareScheduleTmpl,
            parent: angular.element(document.body),
            targetEvent: $event,
            clickOutsideToClose: true,
            locals: {
                scheduleId: this.id,
                showSharing: this.showSharing,
            },
            resolve: {
                tokenData: () => {
                    if (this.showSharing) {
                        return this.TeamCalClientService.getSharingToken(this.id).then(response => {
                            return response.data;
                        });
                    } else {
                        return undefined;
                    }
                },
            }
        });
    }

    moveRowClicked(rowId, dirOrBeforeRowId) {
        const rowOrder = this.scheduler.moveRow(rowId, dirOrBeforeRowId);
        return this.TeamCalClientService.updateScheduleRowOrders(this.id, rowOrder.current, rowOrder.previous).catch(() => {
            let toast = this.$mdToast.simple().hideDelay(30000).action('Refresh').highlightAction(true);
            toast = toast.textContent(`Another user has changed the row order. Please refresh schedule view and try again.`);
            this.$mdToast.show(toast).then(response => {
                if (response === 'ok') {
                    location.reload();
                }
           });
        });
    }

    saveScheduleOptions() {
        return this.TeamCalClientService.updateScheduleOptions(
            this.id,
            this.settings.workWeekStart,
            this.settings.workWeekEnd,
            this.settings.workDayStart,
            this.settings.workDayEnd,
            this.settings.timezone,
            this.settings.moveAction
        );
    }

    saveScheduleUserOptions() {
        return this.TeamCalClientService.updateScheduleUserOptions(
            this.id,
            this.settings.view,
            this.settings.mode,
            this.settings.showWorkHours,
            this.settings.showWeekends,
            this.settings.allDaySettings,
            this.settings.outOfOfficeSettings
        );
    }

    transformLegacyViewScales(viewScale) {
        // TODO: Deprecated - delete once all customers use newest UI
        if (viewScale === 'wf') {
            return 'w';
        } else if (viewScale === 'wwf') {
            return 'ww';
        } else {
            return viewScale;
        }
    }

    loadSchedule() {
        return this.TeamCalClientService.getSchedule(this.id).then(response => {
            let schedule = response.data;
            this.name = schedule.name;
            this.settings.workWeekStart = schedule.workWeekStart;
            this.settings.workWeekEnd = schedule.workWeekEnd;
            this.settings.workDayStart = schedule.workDayStart;
            this.settings.workDayEnd = schedule.workDayEnd;
            this.settings.timezone = schedule.timezone;
            this.settings.showWorkHours = schedule.showWorkHours;
            this.settings.showWeekends = schedule.showWeekends;
            this.settings.allDaySettings = schedule.allDaySettings;
            this.settings.outOfOfficeSettings = schedule.outOfOfficeSettings;
            this.settings.view = this.transformLegacyViewScales(schedule.viewScale.toLowerCase());
            this.settings.mode = schedule.viewMode.toLowerCase();
            this.settings.moveAction = schedule.moveAction;

            this.showIntro = schedule.rows.length === 0;

            for (let row of schedule.rows) {
                if (row.bgColor !== null || row.bgColor !== undefined) {
                    // Color row if a custom row color is defined
                    row.color = row.bgColor;
                }

                row.visible = true;
            }

            this.setPageTitle();
            this.scheduler.addRows(schedule.rows);
        }).catch(response => {
            if (response.status === 400) {
                // Schedule doesn't exist. Redirect schedules list.
                this.$state.go('base.schedules');
            }

            throw response;
        });
    }

    addRow(name, calendarType, calendarId) {
        return this.TeamCalClientService.addRow(this.id, name, calendarType, calendarId).then(response => {
            let row = response.data;

            if (row.bgColor !== null || row.bgColor !== undefined) {
                row.color = row.bgColor;
            }

            row.visible = true;
            this.scheduler.addRow(row, true);
        });
    }

    updateRow(rowId, name) {
        return this.TeamCalClientService.updateRow(this.id, rowId, name).then((response) => {
            let row = response.data;
            this.scheduler.updateRowName(row.id, row.name);
        });
    }

    refreshScheduler(loadEvents) {
        this.activateRefreshSpinner();

        this.scheduler.refreshView({
            date: this.settings.currentDate,
            view: this.settings.view,
            mode: this.settings.mode,
            workWeekStart: this.settings.workWeekStart,
            workWeekEnd: this.settings.workWeekEnd,
            workDayStart: this.settings.workDayStart,
            workDayEnd: this.settings.workDayEnd,
            timezone: this.settings.timezone || this.account.user.settings.timezone,
            showWorkHours: this.settings.showWorkHours,
            showWeekends: this.settings.showWeekends,
            showWeekNumbers: this.account.user.settings.showWeeks,
        }, loadEvents);
    }

    activateRefreshSpinner() {
        this.isRefreshing = true;
        this.$timeout(() => { this.isRefreshing = false; }, 1200);
    }
}
