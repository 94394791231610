import angular from 'angular';
import material from 'angular-material';
import uirouter from '@uirouter/angularjs';
import messages from 'angular-messages';
import cookies from 'angular-cookies';
import '@progress/kendo-ui/js/kendo.timezones';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

if (window.sentry.dsn) {
    Sentry.init({
        dsn: window.sentry.dsn,
        environment: window.sentry.env,
        release: window.sentry.release,
        integrations: [
            new Integrations.Dedupe(),
            new Integrations.Angular(),
        ],
    });
}

if (window.kendoKey) {
    KendoLicensing.setScriptKey(window.kendoKey);
}

import './common.js';
import indexModule from './index/index.module.js';
import accountModule from './account/account.module.js';
import scheduleModule from './schedule/schedule.module.js';
import schedulesModule from './schedules/schedules.module.js';
import sharingModule from './sharing/sharing.module.js';
import componentsModule from './components/components.module.js';

import '@progress/kendo-ui/css/web/kendo.common-material.css';
import '@progress/kendo-ui/css/web/kendo.material.css';
import 'angular-material/angular-material.css';
import './app.css';

import AppConfig from './app.config.js';
import AppRun from './app.run.js';

const modules = [
        messages,
        material,
        cookies,
        uirouter,
        indexModule,
        accountModule,
        scheduleModule,
        schedulesModule,
        sharingModule,
        componentsModule,
    ];

if (window.sentry.dsn) {
    modules.push(Integrations.Angular.moduleName);
}

let appModule = angular
    .module('app', modules)
    .config(AppConfig)
    .run(AppRun)
    .name;

export default appModule;
