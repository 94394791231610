export default /* @ngInject */ class PlanSelectCtrl {
    constructor($mdDialog, $timeout, $mdToast, IntercomClientService, TeamCalClientService, mode, stripeKey, availablePlans, currentPlan, currentInterval, user) {
        this.$mdDialog = $mdDialog;
        this.$timeout = $timeout;
        this.$mdToast = $mdToast;
        this.Intercom = IntercomClientService;
        this.TeamCalClientService = TeamCalClientService;
        this.mode = mode;
        this.stripeKey = stripeKey;
        this.availablePlans = availablePlans;
        this.currentPlan = currentPlan;
        this.currentInterval = currentInterval;
        this.user = user;
        this.paymentInProgress=false;
    }

    buy($event, name, plan, interval) {
        this.paymentInProgress = true;

        if (this.mode === 'upgrade') {
            this.Intercom.sendEvent('account-subscription-upgrade-checkout', {'plan': plan, 'interval': interval});

            const stripe = Stripe(this.stripeKey);
            this.TeamCalClientService.sessionNewPayment(plan, interval).then(response => {
                const sessionId = response.data.sessionId;

                this.$timeout(() => {
                    this.paymentInProgress = false;
                });

                stripe.redirectToCheckout({
                    sessionId: sessionId
                }).then(result => {
                    this.$timeout(() => {
                        this.paymentInProgress = false;
                    });

                    const error = result.error.message;
                    this.$mdToast.show(this.$mdToast.simple().hideDelay(10000).textContent(`Plan upgrade failed - ${error} - Please try again or contact support.`));
                });
            }, response => {
                this.$timeout(() => {
                    this.paymentInProgress = false;
                });

                this.$mdToast.show(this.$mdToast.simple().hideDelay(10000).textContent(`Card change failed - Server Error / Status: ${response.status} - Please try again or contact support.`));
            });
        } else {
            this.TeamCalClientService.updateSubscription(plan, interval).then(() => {
                this.paymentInProgress = false;
                this.$mdToast.showSimple('Plan changed');
                this.$mdDialog.hide();
                this.Intercom.sendEvent('account-subscription-changed', {'plan': plan});
            }, response => {
                this.paymentInProgress = false;
                let error = response.data.description? response.data.description: 'Server error.';
                this.$mdToast.show(this.$mdToast.simple().hideDelay(10000).textContent(`Plan change failed - ${error.toUpperCase()} Please try again or contact support.`));
            });
        }
    }

    cancel() {
        this.$mdDialog.cancel();
    }
}
