import RichEditTmpl from './richedit.tmpl.html';
import Quill from './quill.editor.js';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

export default /* @ngInject */ function richEdit() {
    return {
        restrict: "E",
        require: 'ngModel',
        scope: {
            api: '=',
            readOnly: '=',
        },
        template: RichEditTmpl,
        link: function(scope, element, attrs, ngModel) {
            const editBtnEl = element.find('#rich-edit-edit-btn');
            const toolbarEl = element.find('#rich-edit-toolbar');
            const containerEl = element.find('#rich-edit-container');

            let initModel = false;

            const quill = new Quill(containerEl[0], {
                modules: {
                    toolbar: toolbarEl[0],
                    magicUrl: true,
                },
                bounds: element[0],
                formats: ['bold', 'italic', 'underline', 'strike', 'list', 'link'],
                theme: 'snow',
            });

            function enable(value) {
                if (value) {
                    element.find('button').not('#rich-edit-edit-btn').css('visibility', 'visible');
                    element.find('#rich-edit-edit-btn').css('display', 'none');
                    quill.enable(true);
                } else {
                    element.find('button').not('#rich-edit-edit-btn').css('visibility', 'hidden');
                    quill.enable(false);
                }
            }

            function setHtml(value) {
                quill.setContents([{insert: '\n'}]);
                quill.clipboard.dangerouslyPasteHTML(0, value);
            }

            function getHtml() {
                const deltaOps = quill.getContents().ops;
                const converter = new QuillDeltaToHtmlConverter(deltaOps, {paragraphTag: '', linkTarget: '', encodeHtml: false});
                const html = converter.convert();

                // Check if text contains HTML tags other than line break tags (<br>)
                // if not, return as pure text in where all line break tags got replaced.
                // That's because some devices like IPhone can't render HTML in event descriptions
                // so let's not use HTML if not necessary.
                const text = html.replaceAll("<br/>", "\n");
                if (text.search(/<[a-z]/) >= 0) {
                    return html;
                } else {
                    return text;
                }
            }

            editBtnEl.click(() => { enable(true); });

            ngModel.$render = () => {
                if (ngModel.$viewValue  !== undefined) {
                    if (!initModel && !scope.readOnly && ngModel.$viewValue.search(/(?:https?:|www.|<a)/) === -1) {
                        // Put in edit mode, if there is no url in text
                        enable(true);
                    }

                    initModel = true;
                    setHtml(ngModel.$viewValue);
                }
            };

            containerEl.find('.ql-editor').on('blur', () => {
                if (!scope.readOnly && initModel) {
                    scope.$evalAsync(() => ngModel.$setViewValue(getHtml()));
                }
            });

            enable(false);
            if (scope.readOnly) {
                toolbarEl.css('display', 'none');
                containerEl.css('border-top', '1px solid #ccc');
            }

            scope.api = {
                getHtml: getHtml
            };
        }
    };
}
