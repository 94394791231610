import angular from 'angular';
import moment from 'moment';

const EVENT_HEIGHTS = {
    'c': 23,
    'n': 40,
    'b': 60,
};

const DAY_COLUMN_WIDTH = 10;
const WEEK_COLUMN_WIDTH = 40;

const HOURLY_MAJOR_TICK_DEFAULT = 1 * 60;
const WEEK_VIEW_DAY_PARTS = 4;
const WEEK_VIEW_PARTS_MAJOR_TICKET_DEFAULT = 24 / WEEK_VIEW_DAY_PARTS * 60;
const FULL_DAY_MAJOR_TICK_DEFAULT = 24 * 60;

export default class SchedulerView {
    constructor(bindings, $timeout) {
        this.bindings = bindings;
        this.$timeout = $timeout;
    }

    createConfig() {
        return [
            {
                type: "extTimeline",
                eventHeight: EVENT_HEIGHTS['n'],
                columnWidth: DAY_COLUMN_WIDTH,
                majorTick: HOURLY_MAJOR_TICK_DEFAULT,
                minorTickCount: 4, // 15 minute sub slots
                dateHeaderTemplate: kendo.template('<span class="k-link k-nav-day md-subhead">#:kendo.toString(data.date, "D")#</span>', {useWithBlock: false}),
                majorTimeHeaderTemplate: kendo.template('<span class="md-body-1 scheduler-view-nowrap notranslate">#:kendo.toString(data.date, "theader")#</span>', {useWithBlock: false}),
            },
            {
                type: 'extTimelineWeek',
                eventHeight: EVENT_HEIGHTS['n'],
                columnWidth: WEEK_COLUMN_WIDTH,
                majorTick: WEEK_VIEW_PARTS_MAJOR_TICKET_DEFAULT, // 4 parts per day
                minorTickCount: 1, // ... sub slots per part
                dateHeaderTemplate: kendo.template('<span class="k-link k-nav-day md-subhead">#:kendo.toString(data.date, "DD")#</span>', {useWithBlock: false}),
                majorTimeHeaderTemplate: kendo.template('<span class="md-body-1 scheduler-view-nowrap notranslate">#:kendo.toString(data.date, "theader")#</span>', {useWithBlock: false}),
            },
            {
                type: 'extTimelineWorkWeek',
                eventHeight: EVENT_HEIGHTS['n'],
                columnWidth: WEEK_COLUMN_WIDTH,
                majorTick: WEEK_VIEW_PARTS_MAJOR_TICKET_DEFAULT, // 4 parts per day
                minorTickCount: 1, // ... sub slot part
                dateHeaderTemplate: kendo.template('<span class="k-link k-nav-day md-subhead">#:kendo.toString(data.date, "DD")#</span>', {useWithBlock: false}),
                majorTimeHeaderTemplate: kendo.template('<span class="md-body-1 scheduler-view-nowrap notranslate">#:kendo.toString(data.date, "theader")#</span>', {useWithBlock: false}),
            },
            {
                type: 'extTimeline2Weeks',
                eventHeight: EVENT_HEIGHTS['n'],
                columnWidth: 30,
                majorTick: FULL_DAY_MAJOR_TICK_DEFAULT,
                minorTickCount: 2, // ... slots per day
                dateHeaderTemplate: kendo.template('<span class="md-subhead scheduler-view-nowrap">#:kendo.toString(data.date, "MMMM yyyy")#</span>', {useWithBlock: false}),
            },
            {
                type: 'extTimelineMonth',
                eventHeight: EVENT_HEIGHTS['n'],
                columnWidth: 30,
                majorTick: FULL_DAY_MAJOR_TICK_DEFAULT,
                minorTickCount: 1, // ... slots per day
                dateHeaderTemplate: kendo.template('<span class="md-subhead scheduler-view-nowrap">#:kendo.toString(data.date, "MMMM yyyy")#</span>', {useWithBlock: false}),
            },
            {
                type: 'extTimeline3Months',
                eventHeight: EVENT_HEIGHTS['n'],
                columnWidth: 8,
                majorTick: FULL_DAY_MAJOR_TICK_DEFAULT,
                minorTickCount: 1, // ... slots per day
                showWeekends: false,
                dateHeaderTemplate: kendo.template('<span class="md-subhead scheduler-view-nowrap">#:kendo.toString(data.date, "MMMM, yyyy")#</span>', {useWithBlock: false}),
            },
            {
                type: 'extTimelineYear',
                eventHeight: EVENT_HEIGHTS['n'],
                columnWidth: 20,
                majorTick: FULL_DAY_MAJOR_TICK_DEFAULT,
                minorTickCount: 1, // ... slots per week
                dateHeaderTemplate: kendo.template('<span class="md-subhead scheduler-view-nowrap">#:kendo.toString(data.date, "MMM, yy")#</span>', {useWithBlock: false}),
            },
        ];
    }

    _generateTextSpans(paramName) {
        /*
            Splits texts into multiple spans if they contain Emoji icons.
            Spans with Emoji icons will have a special `text-emoji` class attached which can be used during PDF export.
         */
        return `#
const spans = ${paramName}.split(/(\\p{Emoji_Presentation}+)/u)
for (let i=0,len=spans.length; i<len; i++){
    const classes = i % 2 != 0 ? 'text-emoji': '';
    if (spans[i].length > 0) {
#
    <span class="#:classes#">#:spans[i]#</span> 
# }} #`;
    }

    createResourceConfig() {
        return kendo.template(`
            <div class="md-subhead" style="display:inline;" data-uid="#:data.value#">
                ${this._generateTextSpans('data.text')}
            </div>`, {useWithBlock: false});
    }

    createEventConfig() {
        return kendo.template(`
            <div class="k-event-template" 
            #if (data.bgColor){# 
                style="background-color:#:data.bgColor#;color:#:data.fgColor#"
            #} else {# 
                style="background-color:#:data.resources[0].color#;color:\\#ffffff" 
            #}#">
            <div class="md-caption k-event-title">
                ${this._generateTextSpans('data.title')}
            </div>

            #if(data.location!=""){# <div class="md-caption k-event-location" style="display:inline;"><br/>#:data.location#</div>#}#
            </div>`, {useWithBlock: false});
    }

    onDataBound(e) {
        const view = e.sender.view();
        this._setHeaders(view);
    }

    refresh(settings) {
        const scheduler = this.bindings.getKendoScheduler();
        if (settings) {
            const kendoOptions = scheduler.options;

            let forceRefresh = kendoOptions.workWeekStart !== settings.workWeekStart |
                kendoOptions.workWeekEnd !== settings.workWeekEnd |
                kendoOptions.workDayStart !== settings.workDayStart |
                kendoOptions.workDayEnd !== settings.workDayEnd |
                kendoOptions.showWorkHours !== settings.showWorkHours |
                kendoOptions.showWeekends !== settings.showWeekends;

            kendoOptions.workWeekStart = settings.workWeekStart;
            kendoOptions.workWeekEnd = settings.workWeekEnd;
            kendoOptions.showWorkHours = settings.showWorkHours;
            kendoOptions.showWeekends = settings.showWeekends;
            kendoOptions.showWeekNumbers = settings.showWeekNumbers;

            forceRefresh |= this._setViewEventHeight(EVENT_HEIGHTS[settings.mode]);
            const forceDateChange = !moment(settings.date).isSame(scheduler.date(), 'day');

            const viewName = this._prepareView(kendoOptions, settings, forceRefresh);
            if (forceDateChange) {
                if (viewName) {
                    // Optimize KendoUI refresh. Date change will already refresh view. Do not call .view('name')
                    scheduler._selectedViewName = viewName;
                }

                scheduler.date(settings.date);
            } else if (viewName) {
                scheduler.view(viewName);
            }
        } else {
            const view = scheduler.view();
            scheduler.view(view.name);
        }
    }

    _prepareView(kendoOptions, settings, forceRefresh) {
        const scheduler = this.bindings.getKendoScheduler();

        if (settings.view === 'd') {
            this._setWorkHours(kendoOptions, settings, 1);
            this._setCSSView('d');
            this._setWeekDescription(settings);
            if (forceRefresh || scheduler.viewName() !== 'extTimeline') {
                return 'extTimeline';
            }
        } else if (settings.view === 'w') {
            return this._prepareWeekView('extTimelineWeek', kendoOptions, settings, forceRefresh);
        } else if (settings.view === 'ww') {
            return this._prepareWeekView('extTimelineWorkWeek', kendoOptions, settings, forceRefresh);
        } else if (settings.view === '2w') {
            return this._prepareMultiWeekView('extTimeline2Weeks', kendoOptions, settings, forceRefresh, true);
        } else if (settings.view === 'm') {
            return this._prepareMultiWeekView('extTimelineMonth', kendoOptions, settings, forceRefresh, false);
        } else if (settings.view === '3m') {
            return this._prepareMultiWeekView('extTimeline3Months', kendoOptions, settings, forceRefresh, false);
        } else if (settings.view === 'y') {
            this._disableWorkHours(kendoOptions);
            this._setCSSView('y');
            this._setDescription(undefined, settings.showWeekNumbers ? 'Week #': undefined, 'Week of');

            if (forceRefresh || scheduler.viewName() !== 'extTimelineYear') {
                return 'extTimelineYear';
            }
        }
    }

    _prepareWeekView(viewName, kendoOptions, settings, forceRefresh) {
        const columns = WEEK_VIEW_DAY_PARTS;
        const workHours = settings.showWorkHours ? this._getWorkHours(settings, 1) : 24;
        const timeSlotHours = Math.ceil(workHours / columns);
        const ticks = timeSlotHours * HOURLY_MAJOR_TICK_DEFAULT;

        this._setWorkHours(kendoOptions, settings, 1, timeSlotHours * columns);
        this._setCSSView('w', `c${timeSlotHours}`);
        this._setWeekDescription(settings);

        const timeline = this.bindings.getKendoScheduler().views[viewName];
        const majorTick = ticks;
        const minorTickCount = timeSlotHours;

        if (forceRefresh || this.bindings.getKendoScheduler().viewName() !== viewName || timeline.majorTick !== majorTick || timeline.minorTickCount !== minorTickCount) {
            timeline.majorTick = majorTick;
            timeline.columnWidth = WEEK_COLUMN_WIDTH / minorTickCount;
            timeline.minorTickCount = minorTickCount;
            return viewName;
        }
    }

    _prepareMultiWeekView(viewName, kendoOptions, settings, forceRefresh, useHourlyMinorTickCount) {
        let majorTick;
        let minorTickCount;
        let subKey;

        if (settings.showWorkHours) {
            const workHours = this._getWorkHours(settings,1);
            this._setWorkHours(kendoOptions, settings, 1);
            majorTick = workHours * HOURLY_MAJOR_TICK_DEFAULT;
            minorTickCount = useHourlyMinorTickCount ? workHours: 1;
        } else {
            majorTick = FULL_DAY_MAJOR_TICK_DEFAULT;
            minorTickCount = useHourlyMinorTickCount ? 24: 1;
            this._disableWorkHours(kendoOptions);
        }

        if (viewName === 'extTimeline2Weeks') {
            subKey = `c${minorTickCount}`;
        } else if (viewName === 'extTimeline3Months') {
            const workDays = kendoOptions.workWeekEnd - kendoOptions.workWeekStart + 1;
            subKey = kendoOptions.showWeekends ? 'c7' : `c${workDays}`;
        }

        this._setCSSView(settings.view, subKey);
        this._setDescription();

        const timeline = this.bindings.getKendoScheduler().views[viewName];
        if (forceRefresh || this.bindings.getKendoScheduler().viewName() !== viewName || timeline.majorTick !== majorTick || timeline.minorTickCount !== minorTickCount || timeline.showWeekends || kendoOptions.showWeekends) {
            timeline.showWeekends = kendoOptions.showWeekends;
            timeline.majorTick = majorTick;
            timeline.minorTickCount = minorTickCount;
            if (minorTickCount !== 1) {
                timeline.columnWidth = 30 / minorTickCount;
            }
            return viewName;
        }
    }

    _getWorkHours(settings, roundToHours) {
        const roundToMinutes = roundToHours * 60;
        const start = Math.floor(settings.workDayStart / roundToMinutes) * roundToMinutes;
        const end = Math.ceil(settings.workDayEnd / roundToMinutes) * roundToMinutes;
        return (end - start) / 60;
    }

    _setWorkHours(kendoOptions, settings, roundToHours, minHoursDuration) {
        const roundToMinutes = roundToHours * 60;
        const start = Math.floor(settings.workDayStart / roundToMinutes) * roundToMinutes;
        const end = minHoursDuration !== undefined ? start + minHoursDuration * 60: Math.ceil(settings.workDayEnd / roundToMinutes) * roundToMinutes;

        const startOfDay = moment().startOf('day');
        kendoOptions.workDayStart = startOfDay.clone().add(start, 'minutes').toDate();
        kendoOptions.workDayEnd = startOfDay.clone().add(end, 'minutes').toDate();
    }

    _disableWorkHours(kendoOptions) {
        kendoOptions.workDayStart = undefined;
        kendoOptions.workDayEnd = undefined;
        kendoOptions.showWorkHours = false;
    }

    _setCSSView(key, subKey = undefined) {
        this.viewCSS = subKey ? `scheduler-view-${key} scheduler-view-${key}-${subKey}` : `scheduler-view-${key}`;
    }

    _setWeekDescription(settings) {
        if (settings.showWeekNumbers) {
            this._setDescription(`Week ${moment(settings.date).week()}`);
        } else {
            this._setDescription();
        }
    }

    _setDescription(text1, text2, text3) {
        this.$timeout(() => {
            try {
                const elements = $('.k-scheduler-times th');
                if (elements) {
                    elements[0].innerHTML = text1 ? `<span class="md-subhead">${text1}</span>`: '';
                    elements[1].innerHTML = text2 ? `<span class="md-body-1">${text2}</span>`: '';
                    elements[2].innerHTML = text3 ? `<span class="md-body-1">${text3}</span>`: '';
                }
            } catch (Exception) { /* Do nothing */
            }
        });
    }

    _setHeaders(view) {
        // Show/Hide minor date header
        if (view.name === 'extTimeline2Weeks' || view.name === 'extTimelineMonth' || view.name === 'extTimeline3Months' || view.name === 'extTimelineYear') {
            const kendoOptions = this.bindings.getKendoScheduler().options;
            if (kendoOptions.showWeekNumbers) {
                view.datesHeader.find("tr:nth-last-child(2)").show();
                view.timesHeader.find("tr:nth-last-child(2)").show();
            } else {
                view.datesHeader.find("tr:nth-last-child(2)").hide();
                view.timesHeader.find("tr:nth-last-child(2)").hide();
            }

            view.datesHeader.find("tr:last").show();
            view.timesHeader.find("tr:last").show();
        } else {
            view.datesHeader.find("tr:nth-last-child(2)").show();
            view.datesHeader.find("tr:last").hide();
            view.datesHeader.find("tr:nth-last-child(2)").show();
            view.timesHeader.find("tr:last").hide();
        }
    }

    _setViewEventHeight(height) {
        let hasChanged = false;

        angular.forEach(this.bindings.getKendoScheduler().views, v => {
            if (v.eventHeight !== height) {
                v.eventHeight = height;
                hasChanged = true;
            }
        });

        return hasChanged;
    }
}
