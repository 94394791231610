export default /* @ngInject */ class SettingsCtrl {
    constructor($mdDialog, $mdDateLocale, TimezoneService, initSettings, accountSettings, showAdvancedSettings) {
        this.$mdDialog = $mdDialog;
        this.TimezoneService = TimezoneService;

        this.accountSettings = accountSettings;
        this.showAdvancedSettings = showAdvancedSettings;
        this.days = $mdDateLocale.days.rotate(accountSettings.weekStart);
        this.zones = TimezoneService.getZones();
        this.errors = {};

        this.settings = {
            timezone: initSettings.timezone? TimezoneService.getMainZone(initSettings.timezone): 'DEFAULT',
            workWeekStart: initSettings.workWeekStart,
            workWeekEnd: initSettings.workWeekEnd,
            workDayStart: initSettings.workDayStart,
            workDayEnd: initSettings.workDayEnd,
            moveAction: initSettings.moveAction,
        };
    }

    cancel() {
        this.$mdDialog.cancel();
    }

    save() {
        this.errors = {};

        if (this.settings.workDayStart == null || this.settings.workDayEnd == null) {
            this.errors.timeInvalid = true;
        }

        if (this.settings.workDayStart >= this.settings.workDayEnd) {
            this.errors.workDayDurationInvalid = true;
        }

        if (!this.errors.timeInvalid && !this.errors.workDayDurationInvalid) {
            this.settings.timezone = this.getValidTimezoneValue(this.settings.timezone);
            this.$mdDialog.hide(this.settings);
        }
    }

    getValidTimezoneValue(timezone) {
        if (timezone === 'DEFAULT') {
            return '';
        } else {
            return this.settings.timezone;
        }
    }
}
