export default /* @ngInject */ class TimezoneService {
    constructor(moment) {
        this.moment = moment;
        this.zones = this._initTimezones();
    }

    getZones() {
        return this.zones;
    }

    getMainZone(iana_zone_key) {
        for(let i=0; i<kendo.timezone.windows_zones.length; i++) {
            let windows_zone = kendo.timezone.windows_zones[i];
            if (windows_zone.zone == iana_zone_key) {
                return this._getTimezoneKeyFromOtherZone(windows_zone.other_zone);
            }
        }

        return iana_zone_key;
    }

    _initTimezones() {
        const zones = [];
        const now = this.moment.utc().toDate();

        for(let zone_title of kendo.timezone.zones_titles) {
            let timezone_key = this._getTimezoneKeyFromOtherZone(zone_title.other_zone);
            if (timezone_key) {
                zones.push({
                    'name': zone_title.name,
                    'key': timezone_key,
                    'offset': kendo.timezone.offset(now, timezone_key),
                });
            }
        }

        // Add missing UTC timezone
        zones.push({
            'name': '(UTC) Coordinated Universal Time',
            'key': 'UTC',
            'offset': kendo.timezone.offset(now, 'UTC'),
        });

        zones.sort((z1, z2) => { return z2.offset - z1.offset; });
        return zones;
    }

    _getTimezoneKeyFromOtherZone(other_zone) {
        for(let i=0; i<kendo.timezone.windows_zones.length; i++) {
            let windows_zone = kendo.timezone.windows_zones[i];
            if (windows_zone.other_zone == other_zone) {
                return windows_zone.zone;
            }
        }

        return undefined;
    }
}
