import angular from 'angular';

export default class SchedulerEventsStorage {
    constructor() {
        this.clearEvents();
    }

    clearEvents() {
        this.events = new Map();
        this.eventsBySharedId = new Map();
    }

    addEvent(event, copy=false) {
        if (copy) event = angular.copy(event);

        this.events.set(event.id, event);

        let sharedIds = this.eventsBySharedId.get(event.sharedId);
        if (sharedIds === undefined) {
            sharedIds = new Set();
            this.eventsBySharedId.set(event.sharedId, sharedIds);
        }

        sharedIds.add(event.id);
    }

    getEvent(eventId) {
        return this.events.get(eventId);
    }

    deleteEvent(event) {
        this.events.delete(event.id);
        const sharedIds = this.eventsBySharedId.get(event.sharedId);
        sharedIds.delete(event.id);
    }

    getSharedEvents(event) {
        const sharedEventIds = this.eventsBySharedId.get(event.sharedId);
        const sharedEvents = [];

        for(let eventId of sharedEventIds) {
            sharedEvents.push(this.events.get(eventId));
        }

        return sharedEvents;
    }

    asArray() {
        // Return copy because Kendo modifies the event
        return angular.copy(Array.from(this.events.values()));
    }
}
