import SchedulerTmpl from './scheduler.tmpl.html';
import SchedulerCtrl from './scheduler.controller';

export default  {
    bindings: {
        api: '=',
        id: '<',
        sharingToken: '<',
        timezone: '<',
        restrictions: '<',
        moveAction: '<',
        contextMenuInit: '&',
        contextMenuSelect: '&',
        editRow: '&',
        editEvent: '&',
        deleteEvent: '&',
        initialized: '&',
    },
    template: SchedulerTmpl,
    controller: SchedulerCtrl,
};
