import '@progress/kendo-ui/js/kendo.ooxml.min';
import moment from 'moment';

export default class SchedulerExport {
    constructor(bindings, $window, schedulerResources, schedulerEvents) {
        this.bindings = bindings;
        this.$window = $window;
        this.resources = schedulerResources;
        this.events = schedulerEvents;
    }

    generatePdf(fileName) {
        const exportFontUrl = this.$window.exportFontUrl;
        const exportFontEmojiUrl = this.$window.exportFontEmojiUrl;

        // Kendo PDF export doesn't support unicode by default and can't use browser fonts.
        // - Use a font supporting unicode and languages like JP, CN etc.
        // - Use separate font for all Emoji icons as those aren't included in the unicode font.
        // (because we split texts containing Emojis into different spans, we can assign them the Emoji font-family)
        kendo.pdf.defineFont({
            // Support for JP/CN and other languages
            'Roboto': `${exportFontUrl}`,
            'text-emoji': `${exportFontEmojiUrl}`,
        });

        const kendoScheduler = this.bindings.getKendoScheduler();
        kendoScheduler.options.pdf = {
            landscape: true,
            fileName: `${fileName}.pdf`,
            title: fileName,
            subject: '',
            proxyURL: '/export',
            proxyTarget: '_self',
            forceProxy: false,
        };

        kendoScheduler.saveAsPDF();
    }

    generateExcel(fileName) {
        const rowHeader = {
            cells: [
                {value: 'Name'},
                {value: 'Start Date'},
                {value: 'End Date'},
                {value: 'Duration (hours)'},
                {value: 'Duration (days)'},
                {value: 'Summary'},
                {value: 'Location'},
            ]
        };

        const schedulerResources = this.resources.getAllSortedByUIOrder();
        const visibleSchedulerEvents = this.events.getAllFiltered();
        let excelRowNum = 1;
        const rows = schedulerResources.map(resource => visibleSchedulerEvents.filter(event => event.resourceId == resource.id).map(event => {
            excelRowNum++;
            return {
                cells: [
                    {value: resource.name},
                    {value: event.allDay ? moment(event.start).startOf('day').toDate(): event.start, format: event.allDay ? 'yyyy-mm-dd': 'yyyy-mm-dd hh:mm'},
                    {value: event.allDay ? moment(event.end).startOf('day').toDate(): event.end, format: event.allDay ? 'yyyy-mm-dd': 'yyyy-mm-dd hh:mm'},
                    {formula: event.allDay ? `=C${excelRowNum}+1-B${excelRowNum}`:`=C${excelRowNum}-B${excelRowNum}`, format: '[h]:mm'},
                    {formula: event.allDay ? `=C${excelRowNum}+1-B${excelRowNum}`:`=C${excelRowNum}-B${excelRowNum}`, format: '0.00'},
                    {value: event.title},
                    {value: event.location},
                ]};
        })).flat();

        const columns = rowHeader.cells.map(() => ({autoWidth: true}));

        const workbook = new kendo.ooxml.Workbook({
            sheets: [{
                name: 'Export',
                columns: columns,
                rows: [rowHeader].concat(rows),
            }]
        });

        workbook.toDataURLAsync().then(dataUrl => {
            kendo.saveAs({
                dataURI: dataUrl,
                fileName: `${fileName}.xlsx`,
                proxyURL: '/export',
                proxyTarget: '_self',
                forceProxy: false,
            });
        });
    }
}
