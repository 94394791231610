import stepModifyEvents from './img/guide_1_events.gif';
import stepModifyRows from './img/guide_2_rows.gif';
import stepChangeView from './img/guide_3_views.gif';

export default /* @ngInject */ class IntroCtrl {
    constructor($mdDialog) {
        this.$mdDialog = $mdDialog;

        this.steps = [
            {
                description: 'Use the mouse to drag, resize, or add an event. Click to edit.',
                img: stepModifyEvents,
            },
            {
                description: 'Click a row to change the order, color, or name.',
                img: stepModifyRows,
            },
            {
                description: 'Use the toolbar to adjust your schedule view.',
                img: stepChangeView,
            }
        ];
    }

    $onInit() {
        this.setStep(0);
    }

    setStep(index) {
        const step = this.steps[index];
        this.stepIndex = index;
        this.stepDescription = step.description;
        this.stepImg = step.img;
    }

    closeClicked() {
        this.$mdDialog.hide();
    }

    previousClicked() {
        this.setStep(this.stepIndex - 1);
    }

    nextClicked() {
        this.setStep(this.stepIndex + 1);
    }
}
