export default /* @ngInject */ class ConfigureSSOCtrl {
    constructor($window, $mdDialog, ssoEnabled) {
        this.$window = window;
        this.$mdDialog = $mdDialog;
        this.ssoEnabled = ssoEnabled;
    }

    info() {
        this.$window.open('https://www.teamcalapp.com/configure-google-sso','_blank');
    }

    cancel() {
        this.$mdDialog.cancel();
    }

    enableSSO() {
        this.$mdDialog.hide(true);
    }

    disableSSO() {
        this.$mdDialog.hide(false);
    }
}
