export default /* @ngInject */ class IntercomClientService {
    constructor($window, moment) {
        this.$window = $window;
        this.moment = moment;

        this.CHROME_EXTENSION_ID = 'dniglbfmfllnnkbjilhjignncgcadojn';
    }

    _intercomAvailable() {
        return this.$window.intercomAppId && this.$window.Intercom;
    }

    _check_extension() {
        try {
            chrome.runtime.sendMessage(this.CHROME_EXTENSION_ID, 'version', (response) => {
                if (response) {
                    this.$window.Intercom('update', {'chrome_extension': response.version});
                }
            });
        } catch (error) {
            /* do nothing */
        }
    }

    init(userId, intercomUserId, createdOn, name, email, isAccountOwner, isTrial, trialExpiration) {
        if (this._intercomAvailable()) {
            this.$window.Intercom('boot', {
              app_id: this.$window.intercomAppId,
              user_id: userId,
              user_hash: intercomUserId,
              created_at: this.moment(createdOn).unix(),
              name: name,
              email: email,
              'account_owner': isAccountOwner,
              'trial': isTrial,
              'trial_exp_at': trialExpiration ? this.moment(trialExpiration).unix(): undefined,
            });

            this._check_extension();
        }
    }

    sendEvent(name, context) {
        if (this._intercomAvailable()) {
            this.$window.Intercom('trackEvent', name, context);
        }
    }
}
