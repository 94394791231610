export default /* @ngInject */ class ColorService {
    constructor() {
        this.rowColors = [
            '#795448',
            '#e67c73',
            '#d50201',
            '#f4511e',
            '#ef6d00',
            '#f09300',
            '#f6bf25',
            '#e4c442',
            '#c0ca33',
            '#7cb343',
            '#029688',
            '#0b8043',
            '#33b679',
            '#039be5',
            '#4185f4',
            '#3f51b5',
            '#7986cb',
            '#b39ddb',
            '#9e69af',
            '#8e24aa',
            '#d81b60',
            '#ad1357',
            '#a79b8e',
            '#616161',
        ];

        this.eventColors = [
            '#d50201',
            '#e67c73',
            '#f4511e',
            '#f6bf25',
            '#33b679',
            '#0b8043',
            '#039be5',
            '#7986cb',
            '#3f51b5',
            '#8e24aa',
            '#616161',
        ];
    }

    getRowColors() {
        return this.rowColors;
    }

    getEventColors() {
        return this.eventColors;
    }
}
