export default /* @ngInject */ class ViewScaleService {
    constructor() {
        this.names = {
            'd': 'Day',
            'w': 'Week',
            'ww': 'Work Week',
            '2w': '2 Weeks',
            'm': 'Month',
            '3m': '3 Months',
            'y': 'Year',
        };
    }

    getName(viewScale) {
        return this.names[viewScale];
    }

    getPublicValue(val) {
        val = val ? val.toLowerCase() : '';

        if (val === 'day') {
            return 'd';
        } else if (val === 'week') {
            return 'w';
        } else if (val === 'workweek') {
            return 'ww';
        } else if (val === '2weeks') {
            return '2w';
        } else if (val === 'month') {
            return 'm';
        } else if (val === '3months') {
            return '3m';
        } else if (val === 'year') {
            return 'y';
        } else {
            return '';
        }
    }
}
