export default /* @ngInject */ class SchedulerTimelineViewsService {
    constructor($window, moment) {
        this.$window = $window;
        this.moment = moment;
    }

    init() {
        // Add to global context, otherwise Kendo UI will not find this component
        this.$window.extTimeline = this._createExtTimelineView('extTimeline', kendo.ui.TimelineView);
        this.$window.extTimelineWeek = this._createExtTimelineView('extTimelineWeek', kendo.ui.TimelineWeekView);
        this.$window.extTimelineWorkWeek = this._createExtTimelineView('extTimelineWorkWeek', kendo.ui.TimelineWorkWeekView);
        this.$window.extTimeline2Weeks = this._createExtTimelineMultiWeekView('extTimeline2Weeks', 2);
        this.$window.extTimelineMonth = this._createExtTimelineMultiWeekView('extTimelineMonth', 4);
        this.$window.extTimeline3Months = this._createExtTimelineMultiWeekView('extTimeline3Months', 12);
        this.$window.extTimelineYear = this._createExtTimelineMultiMonthView('extTimelineYear', 12);
    }

    _monkeyPatchedBaseView(base) {
        /*
            Improve KendoUI Performance:

            - init() shouldn't call group() or currentTime() as those functions
            are already called during when the datasource is bound by Kendo
            - _render() shouldn't call _setContentWidth() or refreshLayout() as those functions
            are already called during when Kendo calls _setRowHeight()
         */

        const patchedTimelineGroupedView = kendo.ui.scheduler.TimelineGroupedView.extend({
            _updateCurrentVerticalTimeMarker: function(ranges, currentTime) {
                /*
                 Update this code if the corresponding KendoUI code changes!
                 - Removed unnecessary layouting operations
                 - Remove arrow in date header
                */

                // Definitions
                const CURRENT_TIME_MARKER_CLASS = "k-current-time";

                // Code
                const view = this._view;
                const elementHtml = "<div class='" + CURRENT_TIME_MARKER_CLASS + "'></div>";
                const left = Math.round(ranges[0].innerRect(currentTime, new Date(currentTime.getTime() + 1), false).left);

                // Use inner content height, do not draw the line to the bottom of the screen but only until the last row
                const contentHeight = view.content.find('.k-scheduler-table')[0].scrollHeight;
                $(elementHtml).prependTo(view.content).css({
                    left: left,
                    width: "1px",
                    height: contentHeight - 1,
                    top: 0
                });
            },
        });

        return base.extend({
            _patched: false,
            _patchOnce: false,
            setPatchOnce: function() {
                this._patched = true;
                this._patchOnce = true;
            },
            init: function (element, options) {
                this._patched = true;
                base.fn.init.call(this, element, options);
                this._patched = false;
            },
            _groups: function () {
                if (!this._patched) base.fn._groups.call(this);
            },
            _currentTime: function (setUpdateTimer) {
                if (!this._patched) base.fn._currentTime.call(this, setUpdateTimer);
            },
            /*
            //Patching leads to display issues in Chrome
            _setContentWidth: function () {
                if (!this._patched) base.fn._setContentWidth.call(this);
            },*/
            refreshLayout: function () {
                if (!this._patched) base.fn.refreshLayout.call(this);
                if (this._patchOnce) {
                    this._patched = false;
                    this._patchOnce = false;
                }
            },
            _getGroupedView: function() {
                return new patchedTimelineGroupedView(this);
            }
        });
    }

    _createExtTimelineView(name, base) {
        return this._monkeyPatchedBaseView(base).extend({
            name: name,
        });
    }

    _createExtTimelineMultiWeekView(name, weeks) {
        const base = this._createExtTimelineView(name, kendo.ui.TimelineMonthView);
        const moment = this.moment;

        return base.extend({
            weeks: weeks,
            /*
                Overwrite `_adjustEvent` to adjust the start or end date of an event if weekends are hidden.
                - Move start to earliest work-day in the week.
                - Move end to latest work-day in the week.
             */
            _adjustEvent: function(event) {
                const showWeekends = this.options.showWeekends ?? true;
                let adjustedEvent = base.fn._adjustEvent.call(this, event);

                if (!showWeekends) {
                    if (!this._isWorkDay(adjustedEvent.occurrence.end)) {
                        const currentEnd = moment(adjustedEvent.occurrence.end).endOf('day');
                        const offset = currentEnd.day() < this._workDays[0] ? -7: 0; // Move back a week if end day is already in next week
                        const end = currentEnd.day(this._workDays[this._workDays.length - 1] + offset);
                        adjustedEvent.occurrence.end = end.toDate();
                        adjustedEvent.occurrence.modified = true;
                    }

                    if (!this._isWorkDay(adjustedEvent.occurrence.start)) {
                        const currentStart = moment(adjustedEvent.occurrence.start).startOf('day');
                        const offset = currentStart.day() > this._workDays[this._workDays.length - 1] ? 7: 0; // Move forward a week if start day is already in next week
                        const start = currentStart.day(this._workDays[0] + offset);
                        adjustedEvent.occurrence.start = start.toDate();
                        adjustedEvent.occurrence.modified = true;
                    }

                    if (adjustedEvent.occurrence.modified) {
                        adjustedEvent = base.fn._adjustEvent.call(this, adjustedEvent.occurrence);
                    }
                }

                return adjustedEvent;
            },
            rangeStartDate: function () {
                if (weeks > 4) {
                    const selectedDate = moment(this.options.date);
                    return selectedDate.startOf('week');
                } else {
                    return moment(this.options.date);
                }
            },
            calculateDateRange: function () {
                const showWeekends = this.options.showWeekends ?? true;
                const selectedDate = this.rangeStartDate().toDate();
                let date = selectedDate, idx, length, dates = [];

                for (idx = 0, length = 7 * weeks; idx < length; idx++) {
                    if (showWeekends || !showWeekends && this._isWorkDay(date)) {
                        dates.push(date);
                    }
                    date = kendo.date.nextDay(date);
                }
                this._render(dates);
            },
            _createTable: function (tableRows, className) {
                if (!tableRows.length) {
                    return "";
                }

                return '<table role="presentation" class="' + kendo.trim('k-scheduler-table ' + (className || "")) + ' k-scheduler-table-multiweek">' +
                       '<tr>' +
                            tableRows.join("</tr><tr>") +
                       '</tr>' +
                       '</table>';
            },
            _datesHeader: function (columnLevels) {
                const minorLevelColumnCount = this._columnCountForLevel(columnLevels.length - 1);
                const dateTableRows = [];
                let columnIndex;

                for (let columnLevelIndex = 0; columnLevelIndex < columnLevels.length; columnLevelIndex++) {
                    const level = columnLevels[columnLevelIndex];
                    const th = [];

                    for (columnIndex = 0; columnIndex < level.length; columnIndex++) {
                        const column = level[columnIndex];
                        let colspan;

                        if (columnLevelIndex === 0) {
                            const currentDate = moment(this._dates[columnIndex]);

                            let columnCountSameMonth = 0;
                            for (let nextColumnIndex = columnIndex; nextColumnIndex < level.length; nextColumnIndex++) {
                                if (currentDate.month() === this._dates[nextColumnIndex].getMonth()) {
                                    columnCountSameMonth++;
                                } else {
                                    break;
                                }
                            }

                            columnIndex += columnCountSameMonth - 1;
                            colspan = columnCountSameMonth * minorLevelColumnCount / level.length;
                        } else if (columnLevelIndex === 1) {
                            const currentDate = moment(this._dates[columnIndex]);

                            let columnCountSameWeek = 0;
                            for (let nextColumnIndex = columnIndex; nextColumnIndex < level.length; nextColumnIndex++) {
                                if (currentDate.week() === moment(this._dates[nextColumnIndex]).week()) {
                                    columnCountSameWeek++;
                                } else {
                                    break;
                                }
                            }

                            columnIndex += columnCountSameWeek - 1;
                            colspan = columnCountSameWeek * minorLevelColumnCount / level.length;

                            const weekText = columnCountSameWeek < 2 ? 'W': 'Week ';
                            column.text = `<span class="md-body-1">${weekText}${currentDate.week()}</span>`; // major header
                        } else {
                            if (weeks > 4) {
                                // Show week long columns
                                const currentDate = moment(this._dates[columnIndex]);

                                let columnCountSameWeek = 0;
                                let lastDateOfWeek = currentDate;
                                for (let nextColumnIndex = columnIndex; nextColumnIndex < level.length; nextColumnIndex++) {
                                    const nextColumnDate = moment(this._dates[nextColumnIndex]);
                                    if (currentDate.week() === nextColumnDate.week()) {
                                        lastDateOfWeek = nextColumnDate;
                                        columnCountSameWeek++;
                                    } else {
                                        break;
                                    }
                                }

                                columnIndex += columnCountSameWeek - 1;
                                colspan = columnCountSameWeek * minorLevelColumnCount / level.length;

                                if (weeks > 10) {
                                    column.text = `<span class="md-body-1">${currentDate.format('D')}. - ${lastDateOfWeek.format('D')}.</span>`; // minor header
                                } else {
                                    column.text = `<span class="md-body-1">${currentDate.format('dd, D')} - ${lastDateOfWeek.format('dd, D')}</span>`; // minor header
                                }
                            } else {
                                // Show day long columns
                                const majorLevelColumnCount = this._columnCountForLevel(columnLevelIndex - 1);
                                colspan = level.length / majorLevelColumnCount;
                                const currentDate = moment(this._dates[columnIndex / colspan]);

                                if (weeks > 2) {
                                    column.text = `<span class="md-body-1">${currentDate.format('dd')}<br />${currentDate.format('D')}</span>`; // minor header
                                } else {
                                    column.text = `<span class="md-body-1">${currentDate.format('ddd')}, ${currentDate.format('D')}</span>`; // minor header;
                                }

                                columnIndex += colspan - 1;
                            }
                        }

                        th.push('<th colspan="' + colspan + '" class="' + (column.className || "") + '">' + column.text + "</th>");
                    }

                    dateTableRows.push(th.join(""));
                }

                return $(
                    '<div class="k-scheduler-header k-state-default">' +
                    '<div class="k-scheduler-header-wrap">' +
                    this._createTable(dateTableRows) +
                    '</div>' +
                    '</div>'
                );
            },
        });
    }

    _createExtTimelineMultiMonthView(name, months) {
        const base = this._createExtTimelineView(name, kendo.ui.TimelineMonthView);
        const moment = this.moment;

        return base.extend({
            months: months,
            /*
                Overwrite `_adjustEvent` to adjust the start or end date of an event if weekends are hidden.
                - Move start to earliest work-day in the week.
                - Move end to latest work-day in the week.
             */
            _adjustEvent: function(event) {
                const showWeekends = this.options.showWeekends ?? true;
                let adjustedEvent = base.fn._adjustEvent.call(this, event);

                if (!showWeekends) {
                    if (!this._isWorkDay(adjustedEvent.occurrence.end)) {
                        const currentEnd = moment(adjustedEvent.occurrence.end).endOf('day');
                        const offset = currentEnd.day() < this._workDays[0] ? -7: 0; // Move back a week if end day is already in next week
                        const end = currentEnd.day(this._workDays[this._workDays.length - 1] + offset);
                        adjustedEvent.occurrence.end = end.toDate();
                        adjustedEvent.occurrence.modified = true;
                    }

                    if (!this._isWorkDay(adjustedEvent.occurrence.start)) {
                        const currentStart = moment(adjustedEvent.occurrence.start).startOf('day');
                        const offset = currentStart.day() > this._workDays[this._workDays.length - 1] ? 7: 0; // Move forward a week if start day is already in next week
                        const start = currentStart.day(this._workDays[0] + offset);
                        adjustedEvent.occurrence.start = start.toDate();
                        adjustedEvent.occurrence.modified = true;
                    }

                    if (adjustedEvent.occurrence.modified) {
                        adjustedEvent = base.fn._adjustEvent.call(this, adjustedEvent.occurrence);
                    }
                }

                return adjustedEvent;
            },
            _renderEvents: function(events, groupIndex, eventGroup) {
                var event;
                var idx;
                var length;

                for (idx = 0, length = events.length; idx < length; idx++) {
                    event = events[idx];

                    if (this._isInDateSlot(event)) {
                        //var isMultiDayEvent = event.isAllDay || event.duration() >= kendo.date.MS_PER_DAY;
                        var container = this.content;

                        //if (isMultiDayEvent || this._isInTimeSlot(event)) {
                            var adjustedEvent = this._adjustEvent(event);
                            var group = this.groups[groupIndex];

                            if (!group._continuousEvents) {
                                group._continuousEvents = [];
                            }

                            //if (this._isInTimeSlot(adjustedEvent.occurrence)) {
                                var ranges = group.slotRanges(adjustedEvent.occurrence, false);
                                var range = ranges[0];
                                if (range) {
                                    var startIndex = range.start.index;
                                    var endIndex = range.end.index;

                                    this._groupedView._renderEvent(eventGroup, event, adjustedEvent, group, range, container, startIndex, endIndex);
                                }
                            //}
                        //}
                    }
                }
            },
            _addTimeSlotToCollection: function (group, cells, cellIndex, cellOffset, dateIndex, time, interval) {
                const showWeekends = this.options.showWeekends ?? true;
                const cell = cells[cellIndex+cellOffset];
                const collection = group.getTimeSlotCollection(0);
                const currentDate = this._dates[dateIndex];
                let currentDateTimeStamp = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));

                if (!showWeekends && currentDateTimeStamp.getUTCDay() < this._workDays[0]) {
                    currentDateTimeStamp = moment(currentDateTimeStamp).add(this._workDays[0] - currentDateTimeStamp.getUTCDay(), 'days').toDate();
                }

                const start = currentDateTimeStamp.getTime() + time;
                const days = showWeekends ? 7: this._workDays[this._workDays.length - 1] - this._workDays[0] + 1;
                const end = start + interval * days;

                collection.addTimeSlot(cell, start, end, true);
            },
            rangeStartDate: function () {
                const selectedDate = moment(this.options.date);
                return selectedDate.startOf('week');
            },
            calculateDateRange: function () {
                let selectedDate = this.rangeStartDate(),
                    start = selectedDate.toDate(),
                    end = selectedDate.add(months, 'months').startOf('week').toDate(),
                    dates = [];

                while (start < end) {
                    dates.push(start);
                    start = kendo.date.addDays(start, 7);
                }

                this._render(dates);
            },
            _createTable: function (tableRows, className) {
                if (!tableRows.length) {
                    return "";
                }

                return '<table role="presentation" class="' + kendo.trim('k-scheduler-table ' + (className || "")) + ' k-scheduler-table-multimonth">' +
                       '<tr>' +
                            tableRows.join("</tr><tr>") +
                       '</tr>' +
                       '</table>';
            },
            _datesHeader: function (columnLevels) {
                const minorLevelColumnCount = this._columnCountForLevel(columnLevels.length - 1);
                const columnCount = columnLevels[columnLevels.length - 1].length;
                const dateTableRows = [];
                let columnIndex;

                for (let columnLevelIndex = 0; columnLevelIndex < columnLevels.length; columnLevelIndex++) {
                    const level = columnLevels[columnLevelIndex];
                    const th = [];

                    for (columnIndex = 0; columnIndex < level.length; columnIndex++) {
                        const column = level[columnIndex];
                        let colspan;

                        if (columnLevelIndex === 0) {
                            const currentDate = moment(this._dates[columnIndex]);

                            let columnCountSameMonth = 0;
                            for (let nextColumnIndex = columnIndex; nextColumnIndex < level.length; nextColumnIndex++) {
                                if (currentDate.month() === this._dates[nextColumnIndex].getMonth()) {
                                    columnCountSameMonth++;
                                } else {
                                    break;
                                }
                            }

                            columnIndex += columnCountSameMonth - 1;
                            colspan = columnCountSameMonth * minorLevelColumnCount / level.length;
                        } else if (columnLevelIndex === 1) {
                            const currentDate = moment(this._dates[columnIndex]);
                            colspan = columnCount/level.length;

                            if (months > 3) {
                                column.text = `<span class="md-body-1">${currentDate.week()}</span>`; // minor header
                            } else {
                                column.text = `<span class="md-body-1">Week ${currentDate.week()}</span>`; // minor header
                            }
                        } else {
                            const currentDate = moment(this._dates[columnIndex]);
                            colspan = columnCount/level.length;

                            if (months > 3) {
                                column.text = `<span class="md-body-1">${currentDate.format('D')}.</span>`; // minor header
                            } else {
                                column.text = `<span class="md-body-1">${currentDate.format('D')}. - ${currentDate.clone().endOf('week').format('D')}.</span>`; // minor header
                            }
                        }

                        th.push('<th colspan="' + colspan + '" class="' + (column.className || "") + '">' + column.text + "</th>");
                    }

                    dateTableRows.push(th.join(""));
                }

                return $(
                    '<div class="k-scheduler-header k-state-default">' +
                    '<div class="k-scheduler-header-wrap">' +
                    this._createTable(dateTableRows) +
                    '</div>' +
                    '</div>'
                );
            },
        });
    }
}
