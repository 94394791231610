import 'moment-range';
import angular from 'angular';
import 'angular-moment';

import clientsModule from '../clients/clients.module.js';

import ScheduleCtrl from './schedule.controller.js';
import ScheduleTmpl from './schedule.tmpl.html';
import EventSearch from './eventsearch.component.js';
import RowFilter from './rowfilter.component.js';
import ViewScaleService from './viewscale.service';

/* @ngInject */ function ModuleConfig($stateProvider) {
    $stateProvider.state('base.schedule', {
        url: 'schedules/{id:[-0-9a-f]{36}}?date',
        template: ScheduleTmpl,
        controller: ScheduleCtrl,
        controllerAs: 'ctrl',
        resolve: {
            account: /* @ngInject */ (AccountService) => {
                return AccountService.get();
            },
        }
    });
}

export default angular
    .module('scheduleModule', [
        'kendo.directives',
        'angularMoment',
        clientsModule
    ])
    .component('eventSearch', EventSearch)
    .component('rowFilter', RowFilter)
    .service('ViewScaleService', ViewScaleService)
    .config(ModuleConfig)
    .name;
