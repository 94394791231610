export default /* @ngInject */ class GoogleAnalyticsClientService {
    constructor($window, $location) {
        this.$window = $window;
        this.$location = $location;
        this.urlPrefix = '/app';
    }

    _gaAvailable() {
        return this.$window.gaId;
    }

    init(userId) {
        if (userId && this._gaAvailable()) {
            gtag('set', {'user_id': userId});
        }
    }

    event(name, parameters) {
        if (this._gaAvailable()) {
            gtag('event', name, parameters);
        }
    }

    pageView() {
        if (this._gaAvailable()) {
            const pagePath = this.urlPrefix + this.$location.path();
            gtag('set', {'page_path': pagePath});
        }
    }
}
