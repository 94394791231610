import IntroCtrl from './intro.controller.js';
import IntroTmpl from './intro.tmpl.html';
import angular from "angular";

export default /* @ngInject */ class IntroService {
    constructor($mdDialog, AccountService, IntercomClientService, GoogleAnalyticsClientService) {
        this.$mdDialog = $mdDialog;
        this.AccountService = AccountService;
        this.Intercom = IntercomClientService;
        this.GoogleAnalyticsClientService = GoogleAnalyticsClientService;
    }

    show() {
        this.AccountService.get(false).then(account => {
            const introShown = account.user.notifications.has('intro_event');
            const isOwner = account.user.isOwner;

            if (introShown || !isOwner) {
                return;
            }

            this.$mdDialog.show({
                controller: IntroCtrl,
                controllerAs: 'ctrl',
                template: IntroTmpl,
                parent: angular.element(document.body),
                clickOutsideToClose: true,
            }).finally(() => {
                this.AccountService.setNotification('intro_event');
                this.Intercom.sendEvent('tutorial-complete');
                this.GoogleAnalyticsClientService.event('tutorial_complete');
            });

            this.Intercom.sendEvent('tutorial-begin');
            this.GoogleAnalyticsClientService.event('tutorial_begin');
        });
    }
}
