import InviteUserCtrl from './inviteuser.controller.js';
import InviteUserTmpl from './inviteuser.tmpl.html';
import ConfigureSSOCtrl from './configuresso.controller.js';
import ConfigureSSOTmpl from './configuresso.tmpl.html';

export default /* @ngInject */ class AccountCtrl {
    constructor($state, $transition$, $mdDateLocale, $mdDialog, $mdToast, IntercomClientService, TeamCalClientService, AccountService, GoogleAnalyticsClientService, TimezoneService, account, previousState) {
        this.$state = $state;
        this.$transitionParams = angular.copy($transition$.params());
        this.previousState = previousState;
        this.$mdDialog = $mdDialog;
        this.$mdToast = $mdToast;
        this.Intercom = IntercomClientService;
        this.TeamCalClientService = TeamCalClientService;
        this.AccountService = AccountService;
        this.GoogleAnalyticsClientService = GoogleAnalyticsClientService;
        this.TimezoneService = TimezoneService;
        this.showRestrictions = AccountService.hasFeature('restrictions');

        this.currentDate = Date.now();
        this.days = $mdDateLocale.days;
        this.zones = TimezoneService.getZones();
        this.stripeKey = account.stripeKey;
        this.email = account.user.email;
        this.isOwner = account.user.isOwner;
        this.ssoEnabled = account.sso;
        this.isOrgAccount = account.isOrgAccount;
        this.settings = {};
        this.restrictions = {};
        this.users = undefined;
        this.subscription = undefined;
        this.invoices = undefined;

        this._setSettings(account.user.settings);
        this._setRestrictions(account.restrictions);

        this.loadSubscription();
        this.loadUsers();
        this.loadInvoiceHistory();
    }

    $onInit() {
        this.AccountService.get(true).then((account) => {
            this._setSettings(account.user.settings);
        });
    }

    _setSettings(accountSettings) {
        if (accountSettings) {
            this.settings.timezone = this.TimezoneService.getMainZone(accountSettings.timezone);
            this.settings.time24h = accountSettings.time24h;
            this.settings.dateOrder = accountSettings.dateOrder;
            this.settings.weekStart = accountSettings.weekStart;
            this.settings.showWeeks = accountSettings.showWeeks;
        }
    }

    _setRestrictions(accountRestrictions) {
        if (accountRestrictions) {
            this.restrictions.add = accountRestrictions.add;
            this.restrictions.edit = accountRestrictions.edit;
            this.restrictions.move = accountRestrictions.move;
            this.restrictions.delete = accountRestrictions.delete;
        }
    }

    goSchedulesClicked() {
        this.$state.go('base.schedules');
    }

    goBackClicked() {
        if (this.previousState.name !== '') {
            this.$state.go(this.previousState.name, this.previousState.params);
        } else {
            this.$state.go('base.schedules');
        }
    }

    timezoneChanged(timezone) {
        this.AccountService.updateTimezone(timezone);
    }

    time24hChanged(enabled) {
        this.AccountService.updateTime24h(enabled);
    }

    dateOrderChanged(order) {
        this.AccountService.updateDateOrder(order);
    }

    weekStartChanged(weekStart) {
        this.AccountService.updateWeekStart(weekStart);
    }

    showWeeksChanged(enabled) {
        this.AccountService.updateShowWeeks(enabled);
    }

    restrictionsChanged() {
        this.AccountService.setRestrictions(
            this.restrictions.add,
            this.restrictions.edit,
            this.restrictions.move,
            this.restrictions.delete
        );
    }

    deleteAccount($event) {
        let deleteDialog = this.$mdDialog.prompt()
            .title('Delete account')
            .textContent('Please confirm again if you want to delete your account. A deleted account cannot be restored.')
            .placeholder('Type `delete` to confirm')
            .ok('Delete')
            .cancel('Cancel')
            .targetEvent($event);

        this.$mdDialog.show(deleteDialog).then((confirmToken) => {
            confirmToken = (confirmToken || '').toLowerCase();

            if (confirmToken == 'delete' || confirmToken == '`delete`') {
                this.TeamCalClientService.deleteAccount().then(() => {
                    this.Intercom.sendEvent('account-deleted');
                    this.accountDeletedAlert();
                });
            }
        });
    }

    accountDeletedAlert() {
        let deleteDialog = this.$mdDialog.alert()
            .title('Delete account')
            .textContent('You account has been deleted.')
            .ok('OK');

        this.$mdDialog.show(deleteDialog).then(() => {
            // Redirect to login page
            window.location.replace('https://www.teamcalapp.com');
        });
    }

    loadSubscription(force=false) {
        if (!this.isOrgAccount && this.isOwner && (this.subscription === undefined || force)) {
            this.TeamCalClientService.getSubscription().then((response) => {
                this.subscription = response.data;

                if (this.$transitionParams.upgrade) {
                    this.$transitionParams.upgrade = false;
                    if (this.subscription.plan === 'trial') {
                        this.upgradePlan();
                    } else {
                        this.changePlan();
                    }
                }

                if (this.$transitionParams.payment === 'successful') {
                    this.planPurchased();
                } else if (this.$transitionParams.payment === 'setup') {
                    this.cardChanged();
                }

                this.$transitionParams.payment = undefined;
            });
        }
    }

    loadInvoiceHistory() {
        if (this.isOwner && this.invoices === undefined) {
            this.TeamCalClientService.getInvoiceHistory().then((response) => {
                this.invoices = response.data;
            });
        }
    }

    loadUsers() {
        if (this.isOwner && this.users === undefined) {
            this.TeamCalClientService.listUsers().then((response) => {
                this.users = response.data.users;
            });
        }
    }

    inviteUser($event) {
        this.$mdDialog.show({
            controller: InviteUserCtrl,
            controllerAs: 'ctrl',
            template: InviteUserTmpl,
            parent: angular.element(document.body),
            targetEvent: $event,
            clickOutsideToClose: true,
            resolve: {
                invitation: () => {
                    this.Intercom.sendEvent('account-user-invite');
                    return this.TeamCalClientService.inviteUser();
                }
            }
        });
    }

    deleteUser(user, $event) {
        let deleteDialog = this.$mdDialog.confirm()
            .title('Delete user')
            .textContent(`Do you really want to delete user ${user.email}?`)
            .ok('Delete')
            .cancel('Cancel')
            .targetEvent($event);

        this.$mdDialog.show(deleteDialog).then(() => {
            this.TeamCalClientService.deleteUser(user.email).then(() => {
                for(let i=0; i<this.users.length; i++) {
                    if (this.users[i].email === user.email) {
                        this.users.splice(i, 1);
                        this.Intercom.sendEvent('account-user-delete', {'email': user.email});
                        break;
                    }
                }
            });
        });
    }

    configureSSO($event) {
        this.$mdDialog.show({
            controller: ConfigureSSOCtrl,
            controllerAs: 'ctrl',
            template: ConfigureSSOTmpl,
            parent: angular.element(document.body),
            targetEvent: $event,
            clickOutsideToClose: true,
            locals: {
              ssoEnabled: this.ssoEnabled,
            },
        }).then((enabled) => {
            return this.TeamCalClientService.configureSSO(enabled).then(() => {
                this.ssoEnabled = enabled;

                if (enabled) {
                    this.$mdToast.show(this.$mdToast.simple().textContent('Single Sign-On (SSO) enabled'));
                    this.Intercom.sendEvent('account-sso-enable');
                } else {
                    this.$mdToast.show(this.$mdToast.simple().textContent('Single Sign-On (SSO) disabled'));
                    this.Intercom.sendEvent('account-sso-disable');
                }
            }).catch( response => {
                if (response.status === 400 && response.data.code === 'GSuiteAccountRequired') {
                    this.$mdToast.show(this.$mdToast.simple().hideDelay(10000).textContent('G-Suite account is required to enable SSO. Personal GMail accounts are not supported.'));
                }
            });
        });
    }

    upgradePlan($event) {
        this.Intercom.sendEvent('account-subscription-upgrade', {'plan': 'trial'});

        this.AccountService.selectPlan($event, 'upgrade', 'trial', 1).then(() => {
            this._update_subscription();
        });
    }

    changePlan($event) {
        this.Intercom.sendEvent('account-subscription-change', {'plan': this.subscription.plan});

        this.AccountService.selectPlan($event, 'change', this.subscription.plan, this.subscription.interval).then(() => {
            this._update_subscription();
        });
    }

    planPurchased() {
        this.$mdToast.showSimple('Plan upgrade succeeded');
        this.Intercom.sendEvent('account-subscription-upgraded', {'plan': this.subscription.plan});

        const purchaseValue = this.subscription.plan === 'starter' ? 29: this.subscription.plan === 'premium' ? 69: this.subscription.plan === 'business' ? 149: undefined;
        this.GoogleAnalyticsClientService.event('purchase', {
            currency: 'USD',
            value: purchaseValue,
            transaction_id: this.$transitionParams.sessionId,
            items: {
                item_id: this.subscription.plan,
                currency: 'USD',
                price: purchaseValue,
                quantity: 1,
            }
        });
    }

    changeCard() {
        this.Intercom.sendEvent('account-subscription-card-change');

        this.TeamCalClientService.sessionChangePayment().then(response => {
            const sessionId = response.data.sessionId;
            const stripe = Stripe(this.stripeKey);
            stripe.redirectToCheckout({
                sessionId: sessionId
            }).then(result => {
                const error = result.error.message;
                this.$mdToast.show(this.$mdToast.simple().hideDelay(10000).textContent(`Card change failed - ${error} - Please try again or contact support.`));
            });
        }, response => {
            this.$mdToast.show(this.$mdToast.simple().hideDelay(10000).textContent(`Card change failed - Server Error / Status: ${response.status} - Please try again or contact support.`));
        });
    }

    cardChanged() {
        this.$mdToast.showSimple('Card changed');
        this.Intercom.sendEvent('account-subscription-card-changed');
    }

    cancelPlan($event) {
        this.Intercom.sendEvent('account-subscription-cancel');

        let deleteDialog = this.$mdDialog.confirm()
            .title('Cancel subscription')
            .textContent(`Do you really want to cancel your TeamCal subscription?`)
            .ok('Yes')
            .cancel('No')
            .targetEvent($event);

        this.$mdDialog.show(deleteDialog).then(() => {
            this.TeamCalClientService.cancelSubscription().then(() => {
                this._update_subscription();
                this.$mdToast.showSimple('Subscription cancelled');
                this.Intercom.sendEvent('account-subscription-canceled');
            },() => {
                this.$mdToast.show(this.$mdToast.simple().hideDelay(10000).textContent('Cancellation failed. Try again later or contact support.'));
            });
        });
    }

    _update_subscription() {
        this.AccountService.get(true).then(() => {
            this.showRestrictions = this.AccountService.hasFeature('restrictions');
        });

        this.loadSubscription(true);
    }
}
