export default /* @ngInject */ class DebounceFactory {
    constructor($timeout) {
        this.$timeout = $timeout;
    }

    debounce(fn, wait, noPostpone) {
        let args, context, timeout;
        let $timeout = this.$timeout;
        let executed = true;

        function ping() {
            fn.apply(context || this, args || []);
            context = args = null;
            executed = true;
        }

        function cancel() {
            if (timeout) {
                $timeout.cancel(timeout);
                timeout = null;
            }
        }

        function wrapper() {
            context = this;
            args = arguments;
            if (!noPostpone) {
                cancel();
                timeout = $timeout(ping, wait);
            } else if (executed) {
                executed = false;
                timeout = $timeout(ping, wait);
            }
        }

        wrapper.cancel = cancel;
        return wrapper;
    }
}
