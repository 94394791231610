import angular from 'angular';
import clientsModule from '../clients/clients.module.js';

import AccountCtrl from './account.controller.js';
import AccountTmpl from './account.tmpl.html';

/* @ngInject */ function ModuleConfig($stateProvider) {
    $stateProvider.state('base.account', {
        url: 'account?tab&payment&sessionId',
        template: AccountTmpl,
        controller: AccountCtrl,
        controllerAs: 'ctrl',
        params: {
            upgrade: false
        },
        resolve: {
            account: /* @ngInject */ (AccountService) => {
                return AccountService.get();
            },
            previousState: /* @ngInject */ ($state) => {
                return {
                    name: $state.current.name,
                    params: angular.copy($state.params),
                };
            }
        },
    });
}

export default angular
    .module('accountModule', [
        clientsModule
    ])
    .config(ModuleConfig)
    .name;
