export default /* @ngInject */ class IndexCtrl {
    constructor($scope, $state, $transition$, $cookies, TeamCalClientService, GoogleAnalyticsClientService, account) {
        this.$scope = $scope;
        this.$state = $state;
        this.TeamCalClientService = TeamCalClientService;
        this.GoogleAnalyticsClientService = GoogleAnalyticsClientService;
        this.account = account;
        this.refSource = $transition$.params().ref || '';
        this.extInstalled = $transition$.params().ext === 'installed';

        this.crsfToken = $cookies.get('csrftoken');
        this.data = {
            tos: false,
            userFullName: '',
            companyName: '',
        };
    }

    $onInit() {
        if (this.account.user.loggedIn === true) {
            this.$state.go('base.schedules');
        } else {
            // Change viewport for sign-up page on mobile to display page like normal website
            document.querySelector('meta[name~="viewport"]').setAttribute("content", "width=device-width");
        }
    }

    continue($event) {
        // Workaround because Safari doesn't support the required tag
        if (this.data.companyName === undefined || this.data.companyName.trim() === '') {
            this.$scope.signupForm.companyName.$setTouched();
        }

        if (this.data.userFullName === undefined || this.data.userFullName.trim() === '') {
            $event.preventDefault();
            this.$scope.signupForm.userFullName.$setTouched();
        }

        if (!this.data.tos) {
            $event.preventDefault();
            this.$scope.signupForm.tos.$setTouched();
        }
    }

    login() {
        this.$state.go('base.login');
    }
}
