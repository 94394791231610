import Clipboard from 'clipboard';

export default /* @ngInject */ function clipboard() {
    return {
        restrict: "A",
        replace: true,
        link: function(scope, element) {
            let clipboard = new Clipboard(element[0]);

            element.on('$destroy', function() {
                clipboard.destroy();
            });
        }
    };
}
