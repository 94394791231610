import angular from "angular";
import '@progress/kendo-ui/js/kendo.menu.js';
import ContextMenuTmpl from './scheduler.contextMenu.tmpl.html';

const ACTION_TYPE_EVENT = 'Event';
const ACTION_TYPE_ROW = 'Row';
const ACTION_TYPE_ROW_HEADER = 'RowHeader';

export default  {
    bindings: {
        initMenu: '&',
        selectMenu: '&',
    },
    template: ContextMenuTmpl,
    controller: /* @ngInject */ class SchedulerContextMenu {
        constructor() {
            // Bindings
            this.contextMenu = null;
            this.initMenu = null;
            this.selectMenu = null;

            this.preventOpen = false;
        }

        onMenuOpenTriggered(e) {
            const menu = this.contextMenu;
            const target = angular.element(e.target);

            if (e.event && e.event.type === 'click' && this.preventOpen) {
                this.preventOpen = false;
                e.preventDefault();
                return;
            } else {
                this.preventOpen = false;
            }

            let actionType = target.hasClass("k-event") ? ACTION_TYPE_EVENT : target.hasClass('k-slot-cell') ? ACTION_TYPE_ROW_HEADER : ACTION_TYPE_ROW;
            if (e.event && e.event.type === 'click' && actionType === ACTION_TYPE_EVENT) {
                // Do not open context menu on event with simple click
                e.preventDefault();
                return;
            }

            this.initMenu({'$event': e, 'menu': menu, 'actionType': actionType});
        }

        onMenuClosedTriggered(e) {
            if (e.event && e.event.target) {
                const insideHtmlFilterArea = $(this.contextMenu.options.filter).is(e.event.target) || $(e.event.target).parent('.k-slot-cell').length > 0;
                if (e.event.type === 'mousedown' && insideHtmlFilterArea && e.event.originalEvent.button === 0) {
                    // Allow the user to close the context menu with left button without opening another contextmenu
                    this.preventOpen = true;
                }
            }
        }

        onSelectTriggered(e) {
            this.selectMenu({'$event': e});
        }
    }
};
