import angular from 'angular';
import clientsModule from '../clients/clients.module.js';

import IndexCtrl from './index.controller.js';
import IndexTmpl from './index.tmpl.html';
import LoginCtrl from './login.controller.js';
import IFrameLoginCtrl from './iframelogin.controller.js';
import IFrameLoginTmpl from './iframelogin.tmpl.html';

/* @ngInject */ function ModuleConfig($stateProvider) {
    $stateProvider.state('base.index', {
        url: '?ref&ext',
        template: IndexTmpl,
        controller: IndexCtrl,
        controllerAs: 'ctrl',
        resolve: {
            account: /* @ngInject */ (AccountService) => {
                return AccountService.get(true, true);
            },
        }
    }).state('base.login', {
        url: 'login?redirect&force',
        controller: LoginCtrl,
        template: '',
        resolve: {
            account: /* @ngInject */ (AccountService) => {
                return AccountService.get(true, true);
            },
        }
    }).state('base.iframelogin', {
        url: 'iframelogin',
        template: IFrameLoginTmpl,
        controller: IFrameLoginCtrl,
        controllerAs: 'ctrl',
        resolve: {
            account: /* @ngInject */ (AccountService) => {
                return AccountService.get(true, true);
            },
        }
    });
}

export default angular
    .module('indexModule', [
        clientsModule
    ])
    .config(ModuleConfig)
    .name;
