export default /* @ngInject */ class InviteUserCtrl {
    constructor($mdDialog, moment, invitation) {
        this.$mdDialog = $mdDialog;

        this.expiresOn = moment(invitation.data.expiresOn).fromNow();
        this.secret = invitation.data.secret;
        this.joinLink = `https://app.teamcalapp.com/join/${this.secret}`;
    }

    ok() {
        this.$mdDialog.hide();
    }
}
