import 'moment-range';
import angular from 'angular';
import 'angular-moment';

import clientsModule from '../clients/clients.module.js';

import SharedScheduleCtrl from './sharedschedule.controller.js';
import SharedScheduleTmpl from './sharedschedule.tmpl.html';

/* @ngInject */ function ModuleConfig($stateProvider) {
    $stateProvider.state('base.sharing', {
        url: 'sharing/{token:[0-9A-Za-z]*}?date&filter&view&workhours&weeknumbers&weekends&allday&outofoffice&mode&refresh',
        template: SharedScheduleTmpl,
        controller: SharedScheduleCtrl,
        controllerAs: 'ctrl',
        resolve: {
            schedule: /* @ngInject */ ($transition$, TeamCalClientService) => {
                return TeamCalClientService.getSharedSchedule($transition$.params().token).then(response => {return response.data;}, () => {return undefined;});
            },
        }
    });
}

export default angular
    .module('sharingModule', [
        'kendo.directives',
        'angularMoment',
        clientsModule
    ])
    .config(ModuleConfig)
    .name;
