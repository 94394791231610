import EditBoxTmpl from './editbox.tmpl.html';

export default /* @ngInject */ function editBox() {
    return {
        restrict: "E",
        replace: true,
        require: 'ngModel',
        template: EditBoxTmpl,
        link: function(scope, element, attrs, ngModel) {
            function clean(text) {
                return text.replace(/(?:\r\n|\r|\n)/g, '');
            }

            ngModel.$render = function() {
                element.text(clean(ngModel.$viewValue || ''));
            };

            element.on('keydown change', (event) => {
                if (event.keyCode === 27) {
                    event.preventDefault();
                    ngModel.$rollbackViewValue();
                    ngModel.$setViewValue(ngModel.$modelValue);
                    ngModel.$setPristine();
                    element.blur();
                } if (event.keyCode === 13) {
                    event.preventDefault();
                    element.blur();
                }
            });

            element.on('blur', () => {
                scope.$evalAsync(() => {
                    const text = clean(element.text());
                    ngModel.$setViewValue(text);

                    // Remove any <br> or other default elements from contenteditable (e.g. Safari)
                    element.text(text);
                });
            });
        }
    };
}
