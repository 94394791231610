import PlanTmpl from './plan.tmpl.html';

export default  {
    bindings: {
        isPrimary: '<',
        title: '<',
        price: '<',
        period: '<',
        available: '<',
        items: '<',
        action: '<',
        actionFn: '&',
        actionDisabled: '<',
    },
    template: PlanTmpl,
    controller: /* @ngInject */ class PlanComponent {
        constructor($mdColors) {
            this.$mdColors = $mdColors;
        }

        $onInit() {
            if (this.isPrimary) {
                this.background = {'background': this.$mdColors.getThemeColor('default-primary')};
                this.color = {'color': this.$mdColors.getThemeColor('default-background')};
            } else {
                this.background = {'background': this.$mdColors.getThemeColor('default-background-100')};
                this.color = undefined;
            }
        }

        actionClicked($event) {
            this.actionFn({'$event': $event});
        }
    }
};
