import LogoutButtonTmpl from './logoutbutton.tmpl.html';

export default  {
    template: LogoutButtonTmpl,
    controller: /* @ngInject */ class LogoutButtonComponent {
        constructor($cookies) {
            this.$cookies = $cookies;
        }

        $onInit() {
            this.crsfToken = this.$cookies.get('csrftoken');
        }
    }
};
