import angular from 'angular';
import moment from 'moment';

const EVENT_DEFAULT_DURATION_MIN = 30;

export default class SchedulerEvents {
    constructor(bindings, schedulerResources, schedulerEventsStorage) {
        this.bindings = bindings;
        this.resources = schedulerResources;
        this.eventsStorage = schedulerEventsStorage;
    }

    getById(id) {
        return this.bindings.getKendoScheduler().dataSource.get(id);
    }

    getIdFromJsEvent(jsEvent) {
        const eventElement = angular.element(jsEvent.currentTarget || jsEvent.target);
        const eventUid = eventElement.attr("data-uid");
        if (eventUid) {
            const event = this.bindings.getKendoScheduler().occurrenceByUid(eventUid);
            return event.id;
        } else {
            return undefined;
        }
    }

    getAllFiltered() {
        return this.bindings.getKendoScheduler().dataSource.view();
    }

    add(resourceId, title, allDay, start, end, location, description) {
        const defaultDuration = moment.duration(EVENT_DEFAULT_DURATION_MIN, 'minutes');
        const startOrNow = start || moment().startOf('hour').toDate();

        this.bindings.getKendoScheduler().addEvent({
            resourceId: resourceId,
            title: title || '',
            allDay: allDay || false,
            start: startOrNow,
            end: end || moment(startOrNow).add(defaultDuration).toDate(),
            location: location || '',
            description: description,
        });
    }

    addFromJsEvent(jsEvent) {
        const slot = this.bindings.getKendoScheduler().slotByElement(jsEvent.currentTarget || jsEvent.target);
        if (slot) {
            const resources = this.bindings.getKendoScheduler().resourcesBySlot(slot);
            this.add(resources.resourceId, '', false, slot.startDate);
        }
    }

    addFromSearchEvent(resourceId, event) {
        this.bindings.getKendoScheduler().dataSource.pushCreate({
            'id': event.id,
            'sharedId': event.sharedId,
            'resourceId': resourceId,
            'title': event.name,
            'description': undefined,
            'allDay': event.allDay,
            'outOfOffice': false,
            'start': moment(event.start).toDate(),
            'end': moment(event.end).toDate(),
            'fgColor': event.fgColor,
            'bgColor': event.bgColor,
            'location': event.location,
            'organizer': event.organizer,
            'canInviteGuests': event.canInviteGuests,
            'link': event.link,
        });

        this.bindings.getKendoScheduler().editEvent(this.getById(event.id));
    }

    setColor(id, color) {
        const event = this.getById(id);
        if (event) {
            const resource = this.resources.getById(event.resourceId);
            if (resource.color !== color) {
                event.set('bgColor', color);
            } else {
                event.set('bgColor', undefined);
            }
        }
    }

    showEdit(id) {
        const event = this.getById(id);
        this.bindings.getKendoScheduler().editEvent(event);
    }

    duplicate(id, description) {
        const event = this.getById(id);
        this.add( undefined, event.title, event.allDay, event.start, event.end, event.location, description);
    }

    delete(id, showConfirmation) {
        const event = this.getById(id);

        if (showConfirmation) {
            this.bindings.getKendoScheduler().removeEvent(event);
        } else {
            event.deleteConfirmed = true;
            this.bindings.getKendoScheduler().dataSource.remove(event);
            this.bindings.getKendoScheduler().dataSource.sync();
        }
    }

    filterEvents(texts, allDayEventSetting, outOfOfficeEventSetting) {
        const filters = [];

        const titleFilters = texts.map(text => { return {
            field: "title",
            operator: "contains",
            value: text,
        };});
        if (titleFilters.length > 0) {
            filters.push({filters: titleFilters, logic: 'or'});
        }

        const allDay = allDayEventSetting === 'only' ? true: allDayEventSetting === 'hide' ? false: undefined;
        if (allDay !== undefined) {
            filters.push({field: 'allDay', operator: 'eq', value: allDay});
        }

        const outOfOffice = outOfOfficeEventSetting === 'only' ? true: outOfOfficeEventSetting === 'hide' ? false: undefined;
        if (outOfOffice !== undefined) {
            filters.push({field: 'outOfOffice', operator: 'eq', value: outOfOffice});
        }

        if (filters.length > 0) {
            this.bindings.getKendoScheduler().dataSource.filter({
                logic: 'and',
                filters: filters,
            });
        } else if (this.bindings.getKendoScheduler().dataSource.filter() !== undefined) {
            this.bindings.getKendoScheduler().dataSource.filter({});
        }
    }

    isAddAllowed() {
        return this.bindings.getRestrictions() ? !this.bindings.getRestrictions().add: false;
    }

    isEditAllowed() {
        return this.bindings.getRestrictions() ? !this.bindings.getRestrictions().edit: false;
    }

    isMoveAllowed() {
        return this.bindings.getRestrictions() ? !this.bindings.getRestrictions().move: false;
    }

    isDeleteAllowed() {
        return this.bindings.getRestrictions() ? !this.bindings.getRestrictions().delete: false;
    }
}
