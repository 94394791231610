import angular from 'angular';
import clientsModule from '../clients/clients.module.js';

import SchedulesCtrl from './schedules.controller.js';
import SchedulesTmpl from './schedules.tmpl.html';

/* @ngInject */ function ModuleConfig($stateProvider) {
    $stateProvider.state('base.schedules', {
        url: 'schedules',
        template: SchedulesTmpl,
        controller: SchedulesCtrl,
        controllerAs: 'ctrl',
        resolve: {
            accountSettings: /* @ngInject */ (AccountService) => {
                return AccountService.get();
            },
        }
    });
}

export default angular
    .module('schedulesModule', [
        clientsModule
    ])
    .config(ModuleConfig)
    .name;
