export default /* @ngInject */ class SchedulerTooltipService {
    _showTooltip(e) {
        this.tooltip = e.sender;
    }

    hideTooltip() {
        if (this.tooltip) {
            try {
                this.tooltip.hide();
            } catch(Exception) {
                // pass
            }
        }
    }

    _tooltipTemplate() {
        return kendo.template(`
            #var uid = data.target.attr("data-uid");#
            #var scheduler = data.target.closest("[data-role=patchedscheduler]").data("kendoPatchedScheduler");#
            #if (scheduler) {#
              #var model = scheduler.occurrenceByUid(uid);#
              #if(model) {#
                  <div class="scheduler-tooltip-content">
                      #if(model.allDay) {#
                        #if(model.start.getFullYear() != model.end.getFullYear() || model.start.getMonth() != model.end.getMonth() || model.start.getDate() != model.end.getDate()) {#
                            <span class="md-caption">#:kendo.format('{0:DD}',model.start)# - #:kendo.format('{0:DD}',model.end)#</span><br/>
                        #} else {#
                            <span class="md-caption">#:kendo.format('{0:DD}',model.start)#</span><br/>
                        #}#
                      #} else {#
                        <span class="md-caption">#:kendo.format('{0:t}',model.start)# - #:kendo.format('{0:t}',model.end)#</span><br/>
                      #}#
                      <span class="md-body-1">#:model.title#<span>
                  </div>
              #}#
            #}#
        `, {useWithBlock: false});
    }

    createTooltipConfig() {
        return {
            filter: '.k-event:not(.k-event-drag-hint):not(.k-marquee)',
            position: 'top',
            callout: false,
            width: 175,
            show: this._showTooltip,
            content: this._tooltipTemplate(),
        };
    }
}
