export default /* @ngInject */ class KendoService {
    constructor($mdDateLocale, moment) {
        this.$mdDateLocale = $mdDateLocale;
        this.moment = moment;

        this.moment.suppressDeprecationWarnings = true;  // Do not warn about parseString default fallback
    }

    setCulture(weekStart, time24h, dateOrder) {
        let culture = kendo.culture();
        culture.calendars.standard.firstDay = weekStart;
        culture.calendars.standard.patterns.t = time24h ? 'HH:mm': 'h:mm tt';
        culture.calendars.standard.patterns.theader = time24h ? 'HH:mm': 'h tt';
        culture.calendars.standard.patterns.theader_short = time24h ? 'HH': 'h';

        kendo.date.moment = this.moment;

        let momentFormat;
        let momentInputFormat;

        if (dateOrder === 'YMD') {
            momentFormat = 'YYYY-MM-DD';
            momentInputFormat = 'YYYY-M-D';
            culture.calendars.standard.patterns.D = 'yyyy, MMMM d, dddd';
            culture.calendars.standard.patterns.DD = 'MMM d, ddd';
        } else if (dateOrder === 'MDY') {
            momentFormat = momentInputFormat = 'M/D/YYYY';
            culture.calendars.standard.patterns.D = 'dddd, MMMM d, yyyy';
            culture.calendars.standard.patterns.DD = 'ddd, MMM d';
        } else if (dateOrder === 'DMY') {
            momentFormat = momentInputFormat = 'D/M/YYYY';
            culture.calendars.standard.patterns.D = 'dddd, d MMMM, yyyy';
            culture.calendars.standard.patterns.DD = 'ddd, d MMM';
        }

        this.$mdDateLocale.firstDayOfWeek = weekStart;

        this.$mdDateLocale.parseDate = (dateString) => {
            let m = this.moment(dateString, momentInputFormat, true);
            if (!m.isValid()) {
                m = this.moment(dateString);
            }

            return m.isValid() ? m.toDate() : new Date(NaN);
        };

        this.$mdDateLocale.formatDate = (date) => {
            let m = this.moment(date);
            return m.isValid() ? m.format(momentFormat) : '';
        };

        const locale = this.moment.locale();
        if (weekStart === 1) {
            // ISO-8601, Europe
            this.moment.updateLocale(locale, {
                week: {
                    dow: 1, // First day of week is Monday
                    doy: 4  // First week of year must contain 4 January (7 + 1 - 4)
                }
            });
        } else if (weekStart === 0) {
            // US, Canada
            this.moment.updateLocale(locale, {
                week: {
                    dow: 0, // First day of week is Sunday
                    doy: 6  // First week of year must contain 1 January (7 + 0 - 1)
                }
            });
        } else if (weekStart === 6 ){
            // Many middle eastern countries
            this.moment.updateLocale(locale, { week: {
              dow: 6, // First day of week is Saturday
              doy: 12 // First week of year must contain 1 January (7 + 6 - 1)
            }});
        } else {
            this.moment.updateLocale(locale, null);
        }
    }
}
