export default /* @ngInject */ class SharedScheduleCtrl {
    constructor($state, $transition$, $timeout, $interval, $mdMenu, moment, KendoService, ViewScaleService, schedule) {
        this.$state = $state;
        this.$transition = $transition$;
        this.$mdMenu=  $mdMenu;
        this.$interval = $interval;
        this.$timeout = $timeout;
        this.moment = moment;
        this.token = this.$transition.params().token;
        this.ViewScaleService = ViewScaleService;
        this.schedule = schedule;

        this.invalidToken = schedule === undefined;

        if (this.invalidToken) {
            this.settings = {};
        } else {
            const allDayUrlParam = this.parseBoolFromUrl('allday', undefined);
            const outOfOfficeUrlParam = this.parseBoolFromUrl('outofoffice', undefined);

            this.settings = {
                view: this.parseViewFromUrl(),
                currentDate: this.parseDateFromUrl('date').toDate(),
                mode: this.parseModeFromUrl(),
                eventFilter: '',
                showEventFilter: false,
                workWeekStart: schedule.workWeekStart,
                workWeekEnd: schedule.workWeekEnd,
                workDayStart: schedule.workDayStart,
                workDayEnd: schedule.workDayEnd,
                timezone: schedule.timezone,
                showWorkHours: this.parseBoolFromUrl('workhours', true),
                showWeekends: this.parseBoolFromUrl('weekends', true),
                showWeekNumbers: this.parseBoolFromUrl('weeknumbers', false),
                allDaySettings: allDayUrlParam ? 'only' : allDayUrlParam == false ? 'hide': undefined,
                outOfOfficeSettings: outOfOfficeUrlParam ? 'only' : outOfOfficeUrlParam == false ? 'hide': undefined,
                autoRefresh: false,
                autoRefreshPeriod: 5000*60,
            };

            console.log(this.settings.showWeekends);

            KendoService.setCulture(schedule.weekStart, schedule.time24h, schedule.dateOrder);
        }
    }

    schedulerInitialized() {
        this.$timeout(() => {
            if (!this.invalidToken) {
                for(let row of this.schedule.rows) {
                    if (row.bgColor !== null || row.bgColor !== undefined) {
                        row.color = row.bgColor;
                    }
                }

                this.scheduler.addRows(this.schedule.rows);

                this.$timeout(() => {
                    this.refreshScheduler(true, true);
                    this.parseEventFilterFromUrl();
                    if (this.parseBoolFromUrl('refresh', false)) {
                        this.toggleAutoRefreshClicked();
                    }
                });
            }
        });
    }

    getQueryParam(name) {
        const value = this.$transition.params()[name];
        if (Array.isArray(value)) {
            return value ? value[0]: undefined;
        } else {
            return value;
        }
    }

    parseBoolFromUrl(name, defaultVal) {
        const value = this.getQueryParam(name);
        if (value) {
            const val = value ? value.toLowerCase() : '';
            return val === 'true' || val === 'active' || val === 'enabled';
        } else {
            return defaultVal;
        }
    }

    parseDateFromUrl(name) {
        const value = this.getQueryParam(name);
        return value ? this.moment(value): this.moment();
    }

    parseViewFromUrl() {
        const value = this.getQueryParam('view');
        return this.ViewScaleService.getPublicValue(value) || 'd';
    }

    parseModeFromUrl() {
        const value = this.getQueryParam('mode');
        const val = value ? value.toLowerCase() : '';
        if (val === 'compact') {
            return 'c';
        } else if (val === 'large') {
            return 'b';
        } else {
            return 'n';
        }
    }

    parseEventFilterFromUrl() {
        const value = this.getQueryParam('filter');
        if (value) {
            this.settings.showEventFilter = true;
            this.settings.eventFilter = value;
        }

        this.eventFilterRestore();
    }

    toggleEventFilterClicked() {
        this.settings.showEventFilter = !this.settings.showEventFilter;
        this.eventFilterChanged('');
    }

    eventFilterKeyPressed($event) {
        if ($event.keyCode == 27) {
            this.toggleEventFilterClicked();
        }
    }

    eventFilterChanged(value) {
        if (angular.isDefined(value)) {
            this.settings.eventFilter = value;
            let namesToFilter = value.
                replace(/,/g, ' ').
                replace(/;/g, ' ').
                replace(/ {2}/g, ' ').
                split(' ').
                filter((value) => value !== '');

            this.scheduler.filterEvents(namesToFilter, this.settings.allDaySettings, this.settings.outOfOfficeSettings);
        }
    }

    eventFilterRestore() {
        if (this.settings.eventFilter) {
            this.eventFilterChanged(this.settings.eventFilter);
        } else {
            this.scheduler.filterEvents([], this.settings.allDaySettings, this.settings.outOfOfficeSettings);
        }
    }

    currentDateChanged() {
        this.$mdMenu.hide();
        this.refreshScheduler(true);
    }

    changeViewClicked(view) {
        this.settings.view = view;
        this.refreshScheduler(true);
    }

    goToTodayClicked() {
        this.settings.currentDate = this.moment().toDate();
        this.refreshScheduler(true);
    }

    nextDateClicked() {
        this.moveDate(1);
    }

    previousDateClicked() {
        this.moveDate(-1);
    }

    toggleWorkHoursClicked() {
        this.settings.showWorkHours = !this.settings.showWorkHours;
        this.refreshScheduler(false);
    }

    toggleShowWeekendsClicked() {
        this.settings.showWeekends = !this.settings.showWeekends;
        this.refreshScheduler(false);
    }

    toggleAllDayOnlyClicked() {
        if (this.settings.allDaySettings === 'only') {
            this.settings.allDaySettings = undefined;
        } else {
            this.settings.allDaySettings = 'only';
        }

        this.eventFilterRestore();
    }

    toggleHideAllDayClicked() {
        if (this.settings.allDaySettings === 'hide') {
            this.settings.allDaySettings = undefined;
        } else {
            this.settings.allDaySettings = 'hide';
        }

        this.eventFilterRestore();
    }

    toggleOutOfOfficeOnlyClicked() {
        if (this.settings.outOfOfficeSettings === 'only') {
            this.settings.outOfOfficeSettings = undefined;
        } else {
            this.settings.outOfOfficeSettings = 'only';
        }

        this.eventFilterRestore();
    }

    toggleHideOutOfOfficeClicked() {
        if (this.settings.outOfOfficeSettings === 'hide') {
            this.settings.outOfOfficeSettings = undefined;
        } else {
            this.settings.outOfOfficeSettings = 'hide';
        }

        this.eventFilterRestore();
    }

    toggleWorkNumbersClicked() {
        this.settings.showWeekNumbers = !this.settings.showWeekNumbers;
        this.refreshScheduler(false);
    }

    viewModeClicked(mode) {
        this.settings.mode = mode;
        this.refreshScheduler(false);
    }

    refreshClicked() {
        this.refreshScheduler(true, true);
    }

    toggleAutoRefreshClicked() {
        if (this.autoRefreshPromise) {
            this.$interval.cancel(this.autoRefreshPromise);
            this.autoRefreshPromise = undefined;
        }

        if (!this.settings.autoRefresh) {
            this.autoRefreshPromise = this.$interval(() => {
                this.settings.currentDate = this.moment().toDate();
                this.refreshScheduler(true, true);
            }, this.settings.autoRefreshPeriod);

            this.settings.autoRefresh = true;
        } else {
            this.settings.autoRefresh = false;
        }
    }

    moveDate(val) {
        let date = this.moment(this.settings.currentDate);
        if (this.settings.view.indexOf('w') >= 0) {
            this.settings.currentDate = date.add(val, 'weeks').toDate();
        } else if (this.settings.view === 'd') {
            this.settings.currentDate = date.add(val, 'day').toDate();
        } else if (this.settings.view === 'm' || this.settings.view === '3m') {
            this.settings.currentDate = date.add(val, 'weeks').toDate();
        } else if (this.settings.view === 'y') {
            this.settings.currentDate = date.add(val, 'month').toDate();
        }

        this.refreshScheduler(true);
    }

    refreshScheduler(loadEvents) {
        this.scheduler.refreshView({
            date: this.settings.currentDate,
            view: this.settings.view,
            mode: this.settings.mode,
            workWeekStart: this.settings.workWeekStart,
            workWeekEnd: this.settings.workWeekEnd,
            workDayStart: this.settings.workDayStart,
            workDayEnd: this.settings.workDayEnd,
            timezone: this.settings.timezone,
            showWorkHours: this.settings.showWorkHours,
            showWeekends: this.settings.showWeekends,
            showWeekNumbers: this.settings.showWeekNumbers,
        }, loadEvents);
    }
}
