export default /* @ngInject */ class ColorSelectorService {
    constructor($window, ColorService) {
        this.$window = $window;
        this.ColorService = ColorService;
    }

    createButtonHtml(color) {
        return `<button class="k-button" style="background-color:${color};display:inline;" onclick="colorChooser('${color}')">&nbsp;</button>`;
    }

    getChooserHtml(colors, colorCallbackFn) {
        this.$window.colorChooser = colorCallbackFn;
        const buttons = colors.map(this.createButtonHtml);
        let html = '';

        for (let i = 0; i < colors.length/6; i++) {
            // Arrange in 4 rows - each 6 colors in snake like pattern
            const buttonsSlice = buttons.slice(i*6, i*6+6);
            html += `${(i % 2 ? buttonsSlice: buttonsSlice.reverse()).join('')}<br/>`;
        }

        return html;
    }

    getRowColorChooserHtml(colorCallbackFn) {
        return this.getChooserHtml(this.ColorService.getRowColors(), colorCallbackFn);
    }

    getEventColorChooserHtml(rowColor, colorCallbackFn) {
        return this.getChooserHtml(this.ColorService.getEventColors().concat([rowColor]), colorCallbackFn);
    }
}
