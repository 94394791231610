export default /* @ngInject */ class EventEditCtrl {
    constructor($mdDialog, $mdToast, $timeout, moment, TeamCalClientService, OAuthService, accountSettings, accountRestrictions, scheduleId, rows, event) {
        this.$mdDialog = $mdDialog;
        this.$mdToast = $mdToast;
        this.moment = moment;
        this.TeamCalClientService = TeamCalClientService;
        this.OAuthService = OAuthService;
        this.accountSettings = accountSettings;
        this.showDelete = !event.isNew && !accountRestrictions.delete;
        this.scheduleId = scheduleId;
        this.rows = rows;
        this.readOnlyRows = rows ? rows.find((row) => row.role !== 'W') : false;
        this.readOnly = event.readOnly;
        this.contactPermissionsMissing = false;

        this.errors = {};
        this.data = {
            isNew: event.isNew,
            id: event.id,
            title: event.title,
            sourceRowId: event.rowId,
            rowId: event.rowId,
            startDate: this.getDateComponent(event.start),
            startTime: this.getTimeComponent(event.start),
            endDate: this.getDateComponent(event.end),
            endTime: this.getTimeComponent(event.end),
            location: event.location,
            organizer: event.organizer,
            oldDescription: undefined,
            description: undefined,
            canAddAttendees: event.organizer || event.canInviteGuests,
            oldAttendees: undefined,
            attendees: undefined,
            allDay: event.allDay,
            link: event.link,
        };

        if (event.description !== undefined && event.description !== '') {
            this.data.oldDescription = '';
            this.data.description = this.fixGoogleCalendarDescription(event.description);
        }
    }

    getDateComponent(date) {
        return this.moment(date).startOf('day').toDate();
    }

    getTimeComponent(date) {
        const m = this.moment(date);
        return m.hour() * 60 + m.minute();
    }

    getDate(datePart, timePart) {
        const d = this.moment(datePart);
        return this.moment({year: d.year(), month: d.month(), date: d.date(), hour: Math.floor(timePart/60), minute: timePart % 60}).toDate();
    }

    authorize() {
        this.OAuthService.requestPeopleScopes();
    }

    emojiPicked($event) {
        this.data.title = `${$event.emoji}${this.data.title}`;
    }

    descriptionTabSelected() {
        if (this.data.description === undefined) {
            if (this.data.isNew) {
                this.data.oldDescription = '';
                this.data.description = '';
            } else {
                this.TeamCalClientService.getEventDescription(this.scheduleId, this.data.rowId, this.data.id).then(response => {
                    const description = this.fixGoogleCalendarDescription(response.data.description);
                    this.data.oldDescription = description;
                    this.data.description = description;
                }, () => {
                    this.$mdToast.showSimple('Failed to load description. Try again later.');
                });
            }
        }
    }

    fixGoogleCalendarDescription(description) {
        // Google Calendar has a bug when adding description inside the quick edit form it will use new-lines instead HTML <br> line breaks.
        if (description.search('\n') > -1 && description.search('<br>') === -1) {
            return description.replaceAll('\n', '<br/>');
        } else {
            return description;
        }
    }

    attendeesTabSelected() {
        if (this.data.attendees === undefined) {
            if (this.data.isNew) {
                this.data.oldAttendees = [];
                this.data.attendees = [];
            } else {
                this.TeamCalClientService.getEventAttendees(this.scheduleId, this.data.rowId, this.data.id).then(response => {
                    const attendees = response.data.attendees.map(attendee => {
                        if (!attendee.name) {
                            attendee.name = attendee.email;
                        }

                        if (attendee.status === 'needsAction') {
                            attendee.status = 'Awaiting';
                        }

                        return attendee;
                    });

                    this.data.oldAttendees = angular.copy(attendees);
                    this.data.attendees = angular.copy(attendees);
                }, () => {
                    this.$mdToast.showSimple('Failed to load guests. Try again later.');
                });
            }
        }
    }

    searchAttendees(searchText) {
        return this.TeamCalClientService.searchCalendars(['contacts', 'calendars'], searchText, true, 5).then(response => {
            this.contactPermissionsMissing = false;
            let emails = response.data.map(item => item.id);

            return emails.sort((a, b) => {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            });
        }, response  => {
            if (response.data && response.data.code === 'ContactsPermissionsMissing') {
                this.contactPermissionsMissing = true;
            } else {
                this.contactPermissionsMissing = false;
            }
            return [];
        });
    }

    addAttendee() {
        if (this.attendeeSearchText && this.attendeeSearchText.trim()) {
            let email = this.attendeeSearchText.trim().toLowerCase();
            if (email.indexOf('@') === -1) {
                this.$mdToast.showSimple(`${email} is not a valid email address`);
                return;
            }

            let position = this.data.attendees.map(attendee => attendee.email).indexOf(email);
            if (position === -1) {
                this.data.attendees.push({'name': email, 'email': email});
            }
        }

        this.attendeeSearchText = undefined;
    }

    deleteAttendee(email) {
        let position = this.data.attendees.map(attendee => attendee.email).indexOf(email.toLowerCase());
        if (position >= 0) {
            this.data.attendees.splice(position, 1);
        }
    }

    deleteClicked() {
        this.$mdDialog.hide({action: 'delete'});
    }

    cancelClicked() {
        this.$mdDialog.cancel();
    }

    saveClicked() {
        this.errors = {};

        if (!this.data.startDate || !this.data.endDate) {
            this.errors.dateInvalid = true;
        }

        if (!this.data.allDay && (this.data.startTime === undefined || this.data.endTime === undefined)) {
            this.errors.timeInvalid = true;
        }

        if (this.rows && !this.data.rowId) {
            this.errors.rowInvalid = true;
        }

        if (!this.errors.dateInvalid && !this.errors.timeInvalid && !this.errors.rowInvalid) {
            if (this.data.allDay) {
                this.data.startTime = 0;
                this.data.endTime = 60 * 24 - 1; // Last minute before midnight
            }

            let start = this.getDate(this.data.startDate, this.data.startTime);
            let end = this.getDate(this.data.endDate, this.data.endTime);

            if (this.moment(start).isAfter(this.moment(end))) {
                this.errors.dateTimeRangeInvalid = true;
            }

            if (!this.errors.dateTimeRangeInvalid) {
                let description = undefined;

                if (this.descriptionRichEditApi) {
                    // Workaround: For some customer description is not set (blur event issue?). Set it manually.
                    this.data.description = this.descriptionRichEditApi.getHtml();
                    if (this.data.description === '<br/>') {
                        this.data.description = '';
                    }
                }

                if (this.data.oldDescription !== undefined && this.data.oldDescription.trim() !== this.data.description.trim()) {
                    description = this.data.description;
                }

                let attendeeEmails = undefined;
                if (this.data.oldAttendees !== undefined && this.hasAttendeesChanged()) {
                    attendeeEmails = this.data.attendees.map(attendee => attendee.email);
                }

                this.$mdDialog.hide({
                    action: 'save',
                    event: {
                        'title': this.data.title,
                        'sourceRowId': this.data.sourceRowId,
                        'rowId': this.data.rowId,
                        'allDay': this.data.allDay,
                        'start': start,
                        'end': end,
                        'location': this.data.location,
                        'description': description,
                        'attendees': attendeeEmails,
                    },
                });
            }
        }
    }

    hasAttendeesChanged() {
        let a = new Set(this.data.oldAttendees);
        let b = new Set(this.data.attendees);

        if (a.size !== b.size) return true;
        for (let email of a) if (!b.has(email)) return true;

        return false;
    }
}
