export default /* @ngInject */ class AddRowCtrl {
    constructor($mdDialog, TeamCalClientService, OAuthService, existingRows, addRowFn) {
        this.$mdDialog = $mdDialog;
        this.TeamCalClientService = TeamCalClientService;
        this.OAuthService = OAuthService;
        this.existingRowIds = new Set(existingRows.map(row => row.extId));
        this.addRowFn = addRowFn;
        this.selectedItem = undefined;
        this.searchText = '';
        this.calendars = [];
        this.contactPermissionsMissing = false;
    }

    $onInit() {
        this.loadCalendarList();
    }

    close() {
        this.$mdDialog.cancel();
    }

    add(calendar) {
        calendar.added = true;
        this.addRowFn(calendar).then(added => {
            calendar.added = added;
        });
    }

    addAutocomplete() {
        if (this.selectedItem) {
            this.addRowFn(this.selectedItem);
            this.selectedItem = undefined;
            this.searchText = '';
        } else if (this.searchText) {
            let email = this.searchText.trim();
            this.addRowFn({'name': email, 'type': 'G', 'id': email});
            this.selectedItem = undefined;
            this.searchText = '';
        }
    }

    _parseCalendarResponse(calendars) {
        for (let calendar of calendars) {
            if (calendar.id !== calendar.name && calendar.name.trim() !== '' && !calendar.id.endsWith('calendar.google.com')) {
                calendar.displayName = `${calendar.name} (${calendar.id})`;
            } else {
                calendar.displayName = calendar.name || calendar.id;
            }

            calendar.added = this.existingRowIds.has(calendar.id);
        }

        return calendars.sort((a, b) => {
            return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase());
        });
    }

    loadCalendarList() {
        this.TeamCalClientService.searchCalendars(['calendars'], undefined, false, 250).then(response => {
            return this._parseCalendarResponse(response.data);
        },()  => { return []; }).then(response => {
            this.calendars = response;
        });
    }

    authorize() {
        this.OAuthService.requestPeopleScopes();
    }

    searchAutocomplete(searchText) {
        return this.TeamCalClientService.searchCalendars(['contacts', 'calendars'], searchText, false, 5).then(response => {
            this.contactPermissionsMissing = false;
            return this._parseCalendarResponse(response.data);
        }, response  => {
            if (response.data && response.data.code === 'ContactsPermissionsMissing') {
                this.contactPermissionsMissing = true;
            } else {
                this.contactPermissionsMissing = false;
            }

            return [];
        });
    }
}
