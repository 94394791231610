export default /* @ngInject */ class LocalStorageService {
    constructor($window) {
        this.prefix ='teamcal-';
        this.storage = $window.localStorage;
    }

    set(key, value) {
        this.storage.setItem(`${this.prefix}${key}`, value);
    }

    get(key) {
        return this.storage.getItem(`${this.prefix}${key}`);
    }

    delete(key) {
        this.storage.removeItem(`${this.prefix}${key}`);
    }
}
