const MOVE_ACTION_INVITE = 'I';

export default class SchedulerHandlers {
    constructor(bindings, schedulerTooltipService, schedulerResources, schedulerEvents, schedulerEventsHttp) {
        this.bindings = bindings;
        this.schedulerTooltipService = schedulerTooltipService;
        this.resources = schedulerResources;
        this.events = schedulerEvents;
        this.schedulerEventsHttp = schedulerEventsHttp;
    }

    eventMoveStart(e) {
        this.schedulerTooltipService.hideTooltip();
        const event = e.event;
        event.sourceResourceId = undefined;

        if (this.events.isEditAllowed() || this.events.isMoveAllowed()) {
            if (this.resources.isWriteable(event.resourceId)) {
                event.sourceResourceId = event.resourceId;
            } else {
                this.resources.showReadOnlyWarning(event.resourceId);
                e.preventDefault();
            }
        } else {
            e.preventDefault();
        }
    }

    eventMoveEnd(e) {
        if (e === undefined || e.resources === undefined || e.event === undefined) {
            // Prevent errors when KendoUI is triggering this callback with no data
            return;
        }

        // Force Safari to change cursor, otherwise "grabbing" is shown until mouse is moved
        this._resetCursor(e);

        const swappedResource = e.resources.resourceId !== e.event.resourceId;
        if (swappedResource && this.events.isMoveAllowed()) {
            if (e.event.organizer && this.bindings.getMoveAction() === MOVE_ACTION_INVITE) {
                e.preventDefault();
                const resource = this.resources.getById(e.resources.resourceId);
                this.schedulerEventsHttp.inviteAsAttendee(resource, e.event);
            } else if (!this.resources.isWriteable(e.resources.resourceId)) {
                this.resources.showReadOnlyWarning(e.resources.resourceId);
                e.preventDefault();
            }
        } else if (swappedResource && !this.events.isMoveAllowed()) {
            e.preventDefault();
        } else if (!swappedResource && !this.events.isEditAllowed()) {
            e.preventDefault();
        }
    }

    _resetCursor(e) {
        try {
            e.sender._moveDraggable.currentTarget.css('cursor', 'default');
        } catch (ex) {
            /* Do nothing */
        }
    }

    eventResizeStart(e) {
        this.schedulerTooltipService.hideTooltip();
        const event = e.event;
        event.sourceResourceId = undefined;

        if (this.events.isEditAllowed()) {
            if (this.resources.isWriteable(event.resourceId)) {
                event.sourceResourceId = event.resourceId;
            } else {
                this.resources.showReadOnlyWarning(event.resourceId);
                e.preventDefault();
            }
        } else {
            e.preventDefault();
        }
    }

    eventShowEdit(e) {
        this.schedulerTooltipService.hideTooltip();
        e.preventDefault();

        const event = e.event || this.events.getById(this.events.getIdFromJsEvent(e));
        event.sourceResourceId = undefined;

        const isNewEvent = event.isNew();
        if (!isNewEvent && this.events.isEditAllowed() || isNewEvent && this.events.isAddAllowed()) {
            const writeable = this.resources.isWriteable(event.resourceId);
            if (isNewEvent && !writeable) {
                this.resources.showReadOnlyWarning(event.resourceId);
            } else {
                event.sourceResourceId = event.resourceId;

                this.bindings.getEditEventFn()({
                    'event': {
                        'isNew': isNewEvent,
                        'readOnly': !writeable,
                        'id': event.id,
                        'title': event.title,
                        'description': event.description,
                        'rowId': event.resourceId,
                        'allDay': event.allDay,
                        'start': event.start,
                        'end': event.end,
                        'location': event.location,
                        'organizer': isNewEvent ? true : event.organizer,
                        'canInviteGuests': event.canInviteGuests,
                        'link': event.link,
                    },
                    'save': ((e) => {
                        if (writeable) {
                            event.sourceResourceId = e.sourceRowId;
                            event.update({
                                'title': e.title,
                                'resourceId': e.rowId,
                                'allDay': e.allDay,
                                'start': e.start,
                                'end': e.end,
                                'location': e.location,
                                'description': e.description,
                                'attendees': e.attendees,
                            });

                            if (isNewEvent) {
                                this.bindings.getKendoScheduler().dataSource.add(event);
                            }

                            this.bindings.getKendoScheduler().dataSource.sync();
                        } else {
                            this.resources.showReadOnlyWarning(event.resourceId);
                        }
                    }).bind(this),
                });
            }
        }
    }

    eventShowDelete(e) {
        this.schedulerTooltipService.hideTooltip();
        e.preventDefault();

        const event = e.event;
        if (this.events.isDeleteAllowed()) {
            if (this.resources.isWriteable(event.resourceId)) {
                this.bindings.getDeleteEventFn()({
                    'event': {
                        'id': event.id,
                        'title': event.title,
                        'allDay': event.allDay,
                        'start': event.start,
                        'end': event.end,
                        'location': event.location,
                    }
                });
            } else {
                this.resources.showReadOnlyWarning(event.resourceId);
            }
        }
    }
}
