export default /* @ngInject */ function AppRun($window, $rootScope, $state, GoogleAnalyticsClientService) {
    $rootScope.$on('$locationChangeSuccess', () => {
        document.title = $state.get('base').data.defaultPageTitle;
    });

    if ($window.intercomAppId && $window.Intercom) {
        $rootScope.$on('$locationChangeSuccess', () => {
            $window.Intercom('update');
        });
    }

    if ($window.gaId) {
        $rootScope.$on('$locationChangeSuccess', () => {
            GoogleAnalyticsClientService.pageView();
        });

        if ($window.gaAnalytics && $window.gaAnalytics.name) {
            GoogleAnalyticsClientService.event($window.gaAnalytics.name, {'method': $window.gaAnalytics.method });
            $window.gaAnalytics = undefined;
        }
    }
}
