import TimeInputSelectorTmpl from './timeselector.tmpl.html';

export default  {
    bindings: {
        time: '=',
        time24h: '<',
        midnightLast: '<',
    },
    template: TimeInputSelectorTmpl,
    controller: /* @ngInject */ class TimeInputSelectorComponent {
        constructor(moment) {
            this.moment = moment;
        }

        $onInit() {
            this.times = this.generateTimes();
        }

        generateTimes() {
            let times = [];
            let midnight = this.moment.utc().startOf('day');
            let start = this.moment.utc().startOf('day');

            for (let h = 0; h < 24; h++) {
                let time = start.clone().add(this.midnightLast ? h + 1: h, 'hour');
                times.push({
                    key: time.diff(midnight, 'minutes'),
                    val: time.format(this.time24h ? 'HH:mm': 'h a'),
                });
            }

            return times;
        }
    }
};
