import angular from 'angular';

import IntercomClientService from './intercom-api.service.js';
import GoogleAnalyticsClientService from './google-analytics.service.js';
import TeamCalClientService from './teamcal-api.service.js';

export default angular
    .module('clientsModule', [])
    .service('IntercomClientService', IntercomClientService)
    .service('GoogleAnalyticsClientService', GoogleAnalyticsClientService)
    .service('TeamCalClientService', TeamCalClientService)
    .name;
