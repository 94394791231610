export default /* @ngInject */ class IndexCtrl {
    constructor($window, $state, $transition$, GoogleAnalyticsClientService, account) {
        this.$window = $window;
        this.$state = $state;
        this.$transition = $transition$;
        this.GoogleAnalyticsClientService = GoogleAnalyticsClientService;
        this.account = account;
    }

    $onInit() {
        if (this.account.user.loggedIn === true && this.$transition.params().force !== 'true') {
            this.$state.go('base.schedules');
        } else {
            const redirectUrl = this.$transition.params().redirect;
            if (redirectUrl) {
                this.$window.location.replace(`oauth/google?redirect=${encodeURIComponent(redirectUrl)}`);
            }
            else {
                this.$window.location.replace('oauth/google');
            }
        }
    }
}
