import moment from 'moment';

const INVITEE_REFRESH_DELAY = 3000;

export default class SchedulerEventsHttp {
    constructor(bindings, $q, $timeout, $mdToast, teamCalClientService, schedulerResources, schedulerEventsStorage) {
        this.bindings = bindings;
        this.$q = $q;
        this.$timeout = $timeout;
        this.$mdToast = $mdToast;
        this.teamCalClientService = teamCalClientService;
        this.resources = schedulerResources;
        this.eventsStorage = schedulerEventsStorage;
    }

    transportCreate(request) {
        const event = request.data;

        // Since All-Day events are only the date, we need to send the local computer date
        const start = event.allDay ? kendo.timezone.apply(event.start, this.bindings.getTimezone()): event.start;
        const end = event.allDay ? kendo.timezone.apply(event.end, this.bindings.getTimezone()): event.end;

        this.teamCalClientService.addEvent(
            this.bindings.getSchedulerId(),
            event.resourceId,
            event.title,
            event.description,
            event.allDay,
            start,
            end,
            event.location,
            event.attendees
        ).then((response) => {
            const createdEvent = this._parseEventResponse(response.data, event.resourceId);
            this.eventsStorage.addEvent(createdEvent, true);
            request.success(createdEvent);

            this.refreshInviteeEvents([], createdEvent, event.attendees);
        }, (response) => {
            request.error(response, response.status, response.data);
        });
    }

    transportRead(request) {
        request.success(this.eventsStorage.asArray());
    }

    transportUpdate(request) {
        const event = request.data;
        const resourceId = event.sourceResourceId || event.resourceId;
        const targetResourceId = event.resourceId;
        const swappedResource = resourceId !== targetResourceId;

        event.sourceResourceId = undefined;

        let start = event.start;
        let end = event.end;

        if (event.allDay) {
            // Since All-Day events are only the date, we need to send the local computer date
            start = kendo.timezone.apply(start, this.bindings.getTimezone());
            end = kendo.timezone.apply(end, this.bindings.getTimezone());

            if (event.isAllDay) {
                // Kendo ALL_DAY ; adjusted the end-time to 0h. Revert this adjustment back to 23:59h
                end.setHours(23);
                end.setMinutes(59);
            }

            const startOfDay = moment(start).startOf('day');
            const diff = moment(start).diff(startOfDay);
            if (diff !== 0 || end.getHours() === 0) {
                // Fix behavior on mouse move, when off hours are hidden
                start = (start.getHours() <= 12 ? moment(start).startOf('day'): moment(start).startOf('day').add(1, 'days')).toDate();
                end = (end.getHours() >= 12 ? moment(end).startOf('day').add(1, 'days') : moment(end).startOf('day')).add(-1, 'minutes').toDate();
            }
        }

        this.teamCalClientService.updateEvent(
            this.bindings.getSchedulerId(),
            resourceId,
            targetResourceId,
            event.id,
            event.title,
            event.description,
            event.allDay,
            start,
            end,
            event.location,
            event.attendees
        ).then((response) => {
            const updatedEvent = this._parseEventResponse(response.data, event.resourceId);
            const sharedEvents = this.eventsStorage.getSharedEvents(event);

            if (swappedResource) {
                this.eventsStorage.deleteEvent(event);
            }

            this.eventsStorage.addEvent(updatedEvent, true);
            request.success(updatedEvent);

            this.refreshInviteeEvents(sharedEvents, updatedEvent, event.attendees);

            if (swappedResource) {
                // Force refresh because id of event changed. Otherwise event gets deleted on next modify.
                this.bindings.getKendoScheduler().dataSource.read();
            }
        }, (response) => {
            request.error(response, response.status, response.data);
        });
    }

    transportDestroy(request) {
        const event = request.data;

        if (!event.deleteConfirmed) {
            console.log(`Blocked deletion of unconfirmed event: ${event.taskId}`);
            return;
        }

        this.teamCalClientService.deleteEvent(
            this.bindings.getSchedulerId(),
            event.resourceId,
            event.id
        ).then(() => {
            const sharedEvents = this.eventsStorage.getSharedEvents(event);

            this.eventsStorage.deleteEvent(event);
            request.success(event);

            this.refreshInviteeEvents(sharedEvents, event);
        }, (response) => {
            request.error(response, response.status, response.data);
        });
    }

    transportError(e) {
        this.bindings.getKendoScheduler().dataSource.cancelChanges();

        if (e.status === 400) {
            let code = e.errorThrown.code;
            if (code === 'CalendarNotMoveableError') {
                this.$mdToast.showSimple('Only the organizer can move events with invited attendees. Recurring events cannot be moved.');
            } else if (code === 'CalendarError') {
                this.$mdToast.showSimple('Google Calendar operation failed. Try again later.');
            } else if (code === 'CalendarNotWriteable') {
                this.resources.showReadOnlyWarning();
            } else if (code === 'CalendarPermissionDenied') {
                // e.g. happens when event which is moved doesn't exist anymore
                this.resources.showOutdatedWarning(undefined, () => {
                    this.listEvents();
                });
            } else if (code === 'CalendarCredentialsInvalid') {
                this.resources.showCredentialsInvalidWarning(undefined);
            } else {
                this.$mdToast.showSimple(`Operation failed: ${code}`);
            }
        }
    }

    refreshInviteeEvents(sharedEvents, organizerEvent, attendees) {
        if (!organizerEvent.organizer || (sharedEvents.length <= 1 && !attendees)) {
            return;
        }

        this.$timeout(() => {
            // Delay, since Google event updates have a lag on Google side until the data is available
            const resourceIds = new Set();

            // Update all (other) resources which share the same shared event id
            for (let event of sharedEvents) {
                if (event.id !== organizerEvent.id) {
                    this.eventsStorage.deleteEvent(event);
                    resourceIds.add(event.resourceId);
                }
            }

            // Check if there are any rows for the given attendees
            if (attendees && attendees.length > 0) {
                const resources = this.resources.getByExtIds(new Set(attendees));
                for (let resource of resources) resourceIds.add(resource.id);
            }

            if (resourceIds.size > 0) {
                let resources = this.resources.getByIds(resourceIds);
                this.listEvents(resources);
            }
        }, INVITEE_REFRESH_DELAY);
    }

    inviteAsAttendee(resource, sourceEvent) {
        this.teamCalClientService.inviteEventAttendees(
            this.bindings.getSchedulerId(),
            sourceEvent.resourceId,
            sourceEvent.id,
            [resource.extId]
        ).then(() => {
            this.$mdToast.showSimple(`"${resource.name}" invited`);

            this.$timeout(() => {
                this.listEvents([resource]);
            }, INVITEE_REFRESH_DELAY);
        });
    }

    listEvents(resources = undefined) {
        let view = this.bindings.getKendoScheduler().view();
        let from = moment(this._convertToTimezone(view.startDate()));
        let to = moment(this._convertToTimezone(view.endDate())).add(1, 'days');
        let promises = [];

        if (resources === undefined) {
            resources = this.resources.getAll();
            this.eventsStorage.clearEvents();
        }

        for (let resource of resources) {
            promises.push(this._listResourceEvents(resource, from, to));
        }

        return this.$q.all(promises).then(() => {
            try {
                this.bindings.getKendoScheduler().dataSource.read();
            } catch (Exception) { /* Do nothing. Happens if page is not active anymore */
            }
        });
    }

    _listResourceEvents(resource, from, to) {
        let call = undefined;

        if (this.bindings.getSharingToken()) {
            call = this.teamCalClientService.getSharedScheduleEvents(this.bindings.getSharingToken(), resource.id, from, to);
        } else {
            call = this.teamCalClientService.listEvents(this.bindings.getSchedulerId(), resource.id, from, to);
        }

        return call.then(response => {
            let roles = new Set();

            for (let eventData of response.data) {
                roles.add(eventData.role);
                this.eventsStorage.addEvent(this._parseEventResponse(eventData, resource.id));
            }

            if (roles.size === 1) {
                resource.role = roles.values().next().value;
            }
        }, e => {
            if (e.status === 400) {
                let code = e.data.code;
                if (code === 'CalendarCredentialsInvalid') {
                    this.resources.showCredentialsInvalidWarning(resource.id);
                } else {
                    this.$mdToast.showSimple(`Failed to load events for calendar "${resource.name}". Error: ${code}`);
                }
            }
        });
    }

    _convertToTimezone(date) {
        // Convert time from local browser time to timezone set
        return kendo.timezone.remove(date, this.bindings.getTimezone());
    }

    _parseEventResponse(data, resourceId) {
        return {
            'id': data.id,
            'sharedId': data.sharedId,
            'resourceId': resourceId,
            'title': data.name,
            'description': undefined,
            'allDay': data.allDay,
            'outOfOffice': data.outOfOffice,
            'start': data.start,
            'end': data.end,
            'fgColor': data.fgColor,
            'bgColor': data.bgColor,
            'location': data.location,
            'organizer': data.organizer,
            'canInviteGuests': data.canInviteGuests,
            'link': data.link,
        };
    }
}
