export default /* @ngInject */ class ShareScheduleCtrl {
    constructor($state, $mdDialog, IntercomClientService, TeamCalClientService, scheduleId, showSharing, tokenData) {
        this.$state = $state;
        this.$mdDialog = $mdDialog;
        this.Intercom = IntercomClientService;
        this.TeamCalClientService = TeamCalClientService;
        this.scheduleId = scheduleId;
        this.showSharing = showSharing;

        const safeTokenData = tokenData || {};

        this.settings = {
            hideEventNames: safeTokenData.hideEventNames,
        };

        this.setToken(safeTokenData.token);
    }

    setToken(token) {
        this.token = token;
        this.sharingLink = token ? `https://app.teamcalapp.com/sharing/${token}`: undefined;
    }

    createLink() {
        this.TeamCalClientService.createSharingToken(this.scheduleId).then(response => {
            const tokenData = response.data;
            this.settings.hideEventNames = tokenData.hideEventNames;
            this.setToken(tokenData.token);
            this.Intercom.sendEvent('schedule-sharing-created', {'id': this.scheduleId, 'url': this.sharingLink});
        });
    }

    updateLink() {
        this.TeamCalClientService.updateSharingToken(this.scheduleId, this.token, this.settings.hideEventNames).then(() => {
            this.Intercom.sendEvent('schedule-sharing-updated', {'id': this.scheduleId});
        });
    }

    deleteLink() {
        this.TeamCalClientService.disableSharingToken(this.scheduleId, this.token).then(() => {
            this.setToken(undefined);
            this.Intercom.sendEvent('schedule-sharing-disabled', {'id': this.scheduleId});
        });
    }

    changeHideEventNamesClicked() {
        this.updateLink();
    }

    closeClicked() {
        this.$mdDialog.hide();
    }

    upgradeTeamCalClicked() {
        this.$state.go('base.account', { upgrade: true });
    }
}
