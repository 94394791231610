export default /* @ngInject */ class SchedulesCtrl {
    constructor($state, $mdDialog, TeamCalClientService, AccountService, accountSettings) {
        this.$state = $state;
        this.$mdDialog = $mdDialog;
        this.TeamCalClientService = TeamCalClientService;
        this.AccountService = AccountService;
        this.accountSettings = accountSettings;
        this.schedules = [];
        this.loadSchedules();
    }

    loadSchedules() {
        this.TeamCalClientService.listSchedules().then(response => {
            this.schedules = response.data;
        });
    }

    addScheduleClicked() {
        this.TeamCalClientService.addSchedule('').then(response => {
            let schedule = response.data;
            this.showScheduleClicked(schedule);
        });
    }

    setSchedulerVisibilityClicked($event, schedule) {
        $event.stopPropagation();

        schedule.isPrivate = !schedule.isPrivate;
        this.TeamCalClientService.setSchedulerVisibility(schedule.id, schedule.isPrivate).then(() => {});
    }

    deleteScheduleClicked($event, schedule) {
        $event.stopPropagation();

        let confirm = this.$mdDialog.confirm()
            .title('Delete schedule')
            .textContent('Confirm to delete this team schedule. The linked Google Calendar data will not be deleted.')
            .ariaLabel('Delete team schedule confirm dialog')
            .targetEvent($event)
            .ok('Delete')
            .cancel('Cancel');

        this.$mdDialog.show(confirm).then(() => {
            this.TeamCalClientService.deleteSchedule(schedule.id).then(() => {
                let i = this.schedules.indexOf(schedule);
                if(i != -1) {
                    this.schedules.splice(i, 1);
                }
            });
        });
    }

    showScheduleClicked(schedule) {
        this.$state.go('base.schedule', { id: schedule.id });
    }

    showAccountClicked() {
        this.$state.go('base.account');
    }

    toggleViewModeClicked() {
        if (this.accountSettings.user.settings.schedulesViewMode === 'C') {
            this.AccountService.updateSchedulesViewMode('L');
        } else {
            this.AccountService.updateSchedulesViewMode('C');
        }
    }
}
