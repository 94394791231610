import * as Sentry from '@sentry/browser';
import moment from "moment";

export default /* @ngInject */ class TeamCalClientService {
    constructor($q, $http) {
        this.$http = $http;
        this.$q = $q;
    }

    defaultRetries(method) {
        if (method === 'GET') {
            return 3;
        } else if (method === 'PUT') {
            return 1;
        } else {
            return 0;
        }
    }

    retryableRequest(method, url, data, retries) {
        if (retries === undefined) {
            retries = this.defaultRetries(method);
        }

        return this.$http({method: method, url: url, data: data}).catch(response => {
            if ((response.status === -1 || response.status >= 500) && retries > 0) {
                return this.retryableRequest(method, url, data,retries - 1);
            } else if (response.status === -1) {
                Sentry.withScope(scope => {
                    scope.setExtra('response', response);
                    Sentry.captureMessage('API error');
                });
            }

            throw response;
        });
    }

    redirectLogin(preventRedirect=false) {
        return function(response) {
            if (!preventRedirect && (response.status === 401 || (response.status === 403 && response.data.code !== 'FeatureDisabled'))) {
                // Redirect to login page
                const currentLocation = window.location.pathname;
                if (currentLocation && currentLocation !== '/') {
                    window.location.replace(`/login?redirect=${encodeURIComponent(currentLocation)}`);
                } else {
                    window.location.replace('/');
                }
            }

            throw response;
        };
    }

    getAccount(preventRedirect=false) {
        return this.retryableRequest('GET',`/api/account`).catch(this.redirectLogin(preventRedirect));
    }

    deleteAccount() {
        return this.retryableRequest('DELETE',`/api/account/delete`).catch(this.redirectLogin());
    }

    updateTimezone(timezone) {
        const data = {'timezone': timezone};
        return this.retryableRequest('PUT', `/api/account/settings`, data).catch(this.redirectLogin());
    }

    updateTime24h(enabled) {
        const data = {'time24h': enabled};
        return this.retryableRequest('PUT',`/api/account/settings`, data).catch(this.redirectLogin());
    }

    updateDateOrder(order) {
        const data = {'dateOrder': order};
        return this.retryableRequest('PUT', `/api/account/settings`, data).catch(this.redirectLogin());
    }

    updateWeekStart(weekStart) {
        const data = {'weekStart': weekStart};
        return this.retryableRequest('PUT', `/api/account/settings`, data).catch(this.redirectLogin());
    }

    updateShowWeeks(showWeeks) {
        const data = {'showWeeks': showWeeks};
        return this.retryableRequest('PUT', `/api/account/settings`, data).catch(this.redirectLogin());
    }

    updateSchedulesViewMode(mode) {
        const data = {'schedulesViewMode': mode};
        return this.retryableRequest('PUT',`/api/account/settings`, data).catch(this.redirectLogin());
    }

    setRestrictions(add, edit, move, del) {
        const data = {
            'add': add,
            'edit': edit,
            'move': move,
            'delete': del,
        };

        return this.retryableRequest('PUT',`/api/account/restrictions`, data).catch(this.redirectLogin());
    }

    inviteUser() {
        return this.retryableRequest('POST',`/api/account/users`).catch(this.redirectLogin());
    }

    listUsers() {
        return this.retryableRequest('GET', `/api/account/users`).catch(this.redirectLogin());
    }

    deleteUser(email) {
        return this.retryableRequest('DELETE', `/api/account/users/${email}`).catch(this.redirectLogin());
    }

    configureSSO(enable) {
        const data = {
            'enable': enable,
        };

        return this.retryableRequest('PUT',`/api/account/sso`, data).catch(this.redirectLogin());
    }

    getAccountNotifications() {
        return this.retryableRequest('GET',`/api/account/notifications`);
    }

    setAccountNotification(notification) {
        const data = {'notification': notification};
        return this.retryableRequest('PUT',`/api/account/notifications`, data).catch(this.redirectLogin());
    }

    updateSubscription(plan, interval) {
        const data = {
            'plan': plan,
            'interval': interval,
        };

        return this.retryableRequest('PUT', `/api/account/subscription`, data).catch(this.redirectLogin());
    }

    getSubscription() {
        return this.retryableRequest('GET',`/api/account/subscription`).catch(this.redirectLogin());
    }

    cancelSubscription() {
        return this.retryableRequest('DELETE',`/api/account/subscription`).catch(this.redirectLogin());
    }

    sessionNewPayment(plan, interval) {
        const data = {
            'plan': plan,
            'interval': interval,
        };

        return this.retryableRequest('POST', `/api/account/payment`, data).catch(this.redirectLogin());
    }

    sessionChangePayment() {
        return this.retryableRequest('PUT', `/api/account/payment`).catch(this.redirectLogin());
    }

    getInvoiceHistory() {
        return this.retryableRequest('GET',`/api/account/invoices`).catch(this.redirectLogin());
    }

    addSchedule(name) {
        const data = {'name': name};
        return this.retryableRequest('POST',`/api/schedules`, data).catch(this.redirectLogin());
    }

    getSchedule(scheduleId) {
        return this.retryableRequest('GET',`/api/schedules/${scheduleId}`).catch(this.redirectLogin());
    }

    listSchedules() {
        return this.retryableRequest('GET',`/api/schedules`).catch(this.redirectLogin());
    }

    deleteSchedule(scheduleId) {
        return this.retryableRequest('DELETE',`/api/schedules/${scheduleId}`).catch(this.redirectLogin());
    }

    updateScheduleName(scheduleId, name) {
        const data = {'name': name};
        return this.retryableRequest('PUT',`/api/schedules/${scheduleId}`, data).catch(this.redirectLogin());
    }

    setSchedulerVisibility(scheduleId, isPrivate) {
        const data = {'private': isPrivate};
        return this.retryableRequest('PUT',`/api/schedules/${scheduleId}`, data).catch(this.redirectLogin());
    }

    updateScheduleRowOrders(scheduleId, orderedRowIds, previousOrderedRowIds) {
        const data = {
            'rowOrder': orderedRowIds,
            'previousRowOrder': previousOrderedRowIds,
        };

        return this.retryableRequest('PUT',`/api/schedules/${scheduleId}`, data).catch(this.redirectLogin());
    }

    updateScheduleOptions(scheduleId, workWeekStart, workWeekEnd, workDayStart, workDayEnd, timezone, moveAction) {
        const data = {
            'workWeekStart': workWeekStart,
            'workWeekEnd': workWeekEnd,
            'workDayStart': workDayStart,
            'workDayEnd': workDayEnd,
            'timezone': timezone,
            'moveAction': moveAction,
        };

        return this.retryableRequest('PUT',`/api/schedules/${scheduleId}`, data).catch(this.redirectLogin());
    }

    updateScheduleUserOptions(scheduleId, viewScale, viewMode, showWorkHours, showWeekends, allDaySettings, outOfOfficeSettings) {
        const data = {
            'viewScale': viewScale.toUpperCase(),
            'viewMode': viewMode.toUpperCase(),
            'showWorkHours': showWorkHours,
            'showWeekends': showWeekends,
            'allDaySettings': allDaySettings || '',
            'outOfOfficeSettings': outOfOfficeSettings || '',
        };

        return this.retryableRequest('PUT',`/api/schedules/${scheduleId}`, data).catch(this.redirectLogin());
    }

    addRow(scheduleId, name, calendardType, calendarId) {
        const data = {
            'name': name,
            'type': calendardType,
            'id': calendarId,
        };

        return this.retryableRequest('POST',`/api/schedules/${scheduleId}/rows`, data).catch(this.redirectLogin());
    }

    updateRow(scheduleId, rowId, name, bgColor) {
        const data = {
            'name': name,
            'bgColor': bgColor,
        };

        return this.retryableRequest('PUT',`/api/schedules/${scheduleId}/rows/${rowId}`, data).catch(this.redirectLogin());
    }

    deleteRow(scheduleId, rowId) {
        return this.retryableRequest('DELETE',`/api/schedules/${scheduleId}/rows/${rowId}`).catch(this.redirectLogin());
    }

    listEvents(scheduleId, rowId, from, to) {
        return this.retryableRequest('GET',`/api/schedules/${scheduleId}/rows/${rowId}/events?from=${from.toISOString()}&to=${to.toISOString()}`).catch(this.redirectLogin());
    }

    searchEvents(scheduleId, rowId, from, to, text, limit) {
        return this.retryableRequest('GET',`/api/schedules/${scheduleId}/rows/${rowId}/events?from=${from.toISOString()}&to=${to.toISOString()}&limit=${limit}&text=${text}`).catch(this.redirectLogin());
    }

    getEventDescription(scheduleId, rowId, eventId) {
        return this.retryableRequest('GET',`/api/schedules/${scheduleId}/rows/${rowId}/events/${eventId}/description`).catch(this.redirectLogin());
    }

    getEventAttendees(scheduleId, rowId, eventId) {
        return this.retryableRequest('GET',`/api/schedules/${scheduleId}/rows/${rowId}/events/${eventId}/attendees`).catch(this.redirectLogin());
    }

    inviteEventAttendees(scheduleId, rowId, eventId, attendees) {
        const data = { 'attendees': attendees};
        return this.retryableRequest('POST',`/api/schedules/${scheduleId}/rows/${rowId}/events/${eventId}/attendees`, data).catch(this.redirectLogin());
    }

    _fixTime(date, allDay) {
        // Send all day events without time component
        if (allDay) {
            const currentDate = moment(date);
            return moment.utc([currentDate.year(), currentDate.month(), currentDate.date()]);
        } else {
            return date;
        }
    }

    addEvent(scheduleId, rowId, name, description, allDay, start, end, location, attendees) {
        const data = {
            'name': name,
            'description': description,
            'allDay': allDay,
            'start': this._fixTime(start, allDay),
            'end': this._fixTime(end, allDay),
            'location': location,
            'attendees': attendees,
        };

        return this.retryableRequest('POST',`/api/schedules/${scheduleId}/rows/${rowId}/events`, data).catch(this.redirectLogin());
    }

    updateEvent(scheduleId, rowId, destRowId, eventId, name, description, allDay, start, end, location, attendees) {
        const data = {
            'destRowId': destRowId,
            'name': name,
            'description': description,
            'allDay': allDay,
            'start': this._fixTime(start, allDay),
            'end': this._fixTime(end, allDay),
            'location': location,
            'attendees': attendees,
        };

        return this.retryableRequest('PUT',`/api/schedules/${scheduleId}/rows/${rowId}/events/${eventId}`, data).catch(this.redirectLogin());
    }

    updateEventColor(scheduleId, rowId, eventId, bgColor) {
        const data = {'bgColor': bgColor};
        return this.retryableRequest('PUT',`/api/schedules/${scheduleId}/rows/${rowId}/events/${eventId}`, data).catch(this.redirectLogin());
    }

    deleteEvent(scheduleId, rowId, eventId) {
        return this.retryableRequest('DELETE',`/api/schedules/${scheduleId}/rows/${rowId}/events/${eventId}`).catch(this.redirectLogin());
    }

    searchCalendars(sources, searchText, excludeInternals, max) {
        searchText = encodeURIComponent(searchText || '');
        return this.retryableRequest('GET',`/api/calendars?q=${encodeURIComponent(searchText)}&sources=${sources.toString()}&excludeInternals=${excludeInternals}&max=${max}`).catch(this.redirectLogin());
    }

    createSharingToken(scheduleId) {
        return this.retryableRequest('POST',`/api/schedules/${scheduleId}/share`).catch(this.redirectLogin());
    }

    updateSharingToken(scheduleId, token, hideEventNames) {
        const data = {'hideEventNames': hideEventNames};
        return this.retryableRequest('PUT',`/api/schedules/${scheduleId}/share/${token}`, data).catch(this.redirectLogin());
    }

    getSharingToken(scheduleId) {
        return this.retryableRequest('GET',`/api/schedules/${scheduleId}/share`).catch(this.redirectLogin());
    }

    disableSharingToken(scheduleId, token) {
        return this.retryableRequest('DELETE',`/api/schedules/${scheduleId}/share/${token}`).catch(this.redirectLogin());
    }

    getSharedSchedule(token) {
        const data = {'token': token};
        return this.retryableRequest('PUT',`/api/sharing/schedules`, data).catch(this.redirectLogin());
    }

    getSharedScheduleEvents(token, rowId, from, to) {
        const data = {
            'token': token,
            'rowId': rowId,
            'start': from.toISOString(),
            'end': to.toISOString(),
        };

        return this.retryableRequest('PUT',`/api/sharing/schedules/events`, data).catch(this.redirectLogin());
    }
}
