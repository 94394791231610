export default /* @ngInject */ class EventDeleteCtrl {
    constructor($mdDialog, AccountService, account, event) {
        this.$mdDialog = $mdDialog;
        this.AccountService = AccountService;
        this.hideWarning = account.user.notifications.has('delete_event');
        this.event = event;
    }

    cancelClicked() {
        this.$mdDialog.cancel();
    }

    deleteClicked() {
        if (this.hideWarning) {
            this.AccountService.setNotification('delete_event');
        }

        this.$mdDialog.hide();
    }
}
