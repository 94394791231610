import '@progress/kendo-ui/js/kendo.angular.js';
import '@progress/kendo-ui/js/kendo.scheduler.js';
import '@progress/kendo-ui/js/kendo.tooltip.js';
import './scheduler.patched';

import SchedulerResources from './scheduler.resources';
import SchedulerEventsStorage from './scheduler.events.storage';
import SchedulerEvents from './scheduler.events';
import SchedulerEventsHttp from './scheduler.events.http';
import SchedulerHandlers from './scheduler.handlers';
import SchedulerView from './scheduler.view';
import SchedulerExport from "./scheduler.export";

class Bindings {
    constructor(controller) {
        this.controller = controller;
    }

    getSchedulerId() {
        return this.controller.id;
    }

    getSharingToken() {
        return this.controller.sharingToken;
    }

    getTimezone() {
        return this.controller.timezone;
    }

    getRestrictions() {
        return this.controller.restrictions;
    }

    getMoveAction() {
        return this.controller.moveAction;
    }

    getEditRowFn() {
        return this.controller.editRow;
    }

    getEditEventFn() {
        return this.controller.editEvent;
    }

    getDeleteEventFn() {
        return this.controller.deleteEvent;
    }

    getKendoScheduler() {
        return this.controller.kendoScheduler;
    }
}

export default /* @ngInject */ class SchedulerCtrl {
    constructor($scope, $q, $timeout, $window, $mdToast, TeamCalClientService, SchedulerTooltipService, SchedulerTimelineViewsService) {
        this.$scope = $scope;
        this.$q = $q;
        this.$timeout = $timeout;
        this.$window = $window;
        this.$mdToast = $mdToast;
        this.teamCalClientService = TeamCalClientService;
        this.schedulerTooltipService = SchedulerTooltipService;

        // Bindings
        this.kendoScheduler = null;
        this.kendoSchedulerOptions = null;
        this.tooltipOptions = null;

        this.api = null;
        this.id = null;
        this.sharingToken = null;
        this.timezone = null;
        this.restrictions = null;
        this.moveAction = null;
        this.contextMenuInit = null;
        this.contextMenuSelect = null;
        this.editRow = null;
        this.editEvent = null;
        this.deleteEvent = null;
        this.initialized = null;

        SchedulerTimelineViewsService.init();
    }

    $onInit() {
        const bindings = new Bindings(this);

        this.tooltipOptions = this.schedulerTooltipService.createTooltipConfig();
        this.resources = new SchedulerResources(bindings, this.$window, this.$mdToast);
        this.eventsStorage = new SchedulerEventsStorage();
        this.events = new SchedulerEvents(bindings, this.resources, this.eventsStorage);
        this.http = new SchedulerEventsHttp(bindings, this.$q, this.$timeout, this.$mdToast, this.teamCalClientService, this.resources, this.eventsStorage);
        this.handlers = new SchedulerHandlers(bindings, this.schedulerTooltipService, this.resources, this.events, this.http);
        this.view = new SchedulerView(bindings, this.$timeout);
        this.export = new SchedulerExport(bindings, this.$window, this.resources, this.events);

        this._setKendoSchedulerOptions();
        this._initAPI();

        this.$scope.$on("kendoWidgetCreated", (event, widget) => {
            if (widget === this.kendoScheduler) {
                this._initJsEventHandlers();
                this.initialized();
            }
        });
    }

    _setKendoSchedulerOptions() {
        this.kendoSchedulerOptions = {
            mobile: true,
            height: '100%',
            snap: true,
            allDaySlot: false,
            showWorkHours: false,
            currentTimeMarker: {
                updateInterval: 60 * 1000,
                useLocalTimezone: false,
            },
            editable: {
                create: this.sharingToken === undefined,
                move: this.sharingToken === undefined,
                resize: this.sharingToken === undefined,
                destroy: false,
                confirmation: false,
            },
            footer: false,
            views: this.view.createConfig(),
            groupHeaderTemplate: this.view.createResourceConfig(),
            eventTemplate: this.view.createEventConfig(),
            resources: [{
                name: 'Resources',
                title: 'Resource',
                field: 'resourceId',
                dataTextField: 'name',
                dataValueField: 'id',
                dataSource: {
                    data: [],
                    sort: { field: 'order', dir: 'asc', compare: (...args) => { return this.resources.displayOrderCmp(...args); } },
                },
            }],
            group: {
                resources: ['Resources'],
                orientation: 'vertical',
            },
            dataSource: this._buildKendoDataSource(),
            dataBound: (e) => { this.view.onDataBound(e, this.eventsStorage); },
            moveStart: (...args) => { this.handlers.eventMoveStart(...args); },
            moveEnd: (...args) => { this.handlers.eventMoveEnd(...args); },
            resizeStart: (...args) => { this.handlers.eventResizeStart(...args); },
            edit: (...args) => { this.handlers.eventShowEdit(...args); },
            remove: (...args) => { this.handlers.eventShowDelete(...args); },
        };
    }

    _buildKendoDataSource() {
        return new kendo.data.SchedulerDataSource({
            transport: {
                create: (...args) => { this.http.transportCreate(...args); },
                read:  (...args) => { this.http.transportRead(...args); },
                update:  (...args) => { this.http.transportUpdate(...args); },
                destroy:  (...args) => { this.http.transportDestroy(...args); },
            },
            error:  (...args) => { this.http.transportError(...args); },
            schema: {
                timezone: this.timezone,
                model: {
                    id: 'id',
                    fields: {
                        id: {from: 'id', type: 'string'},
                        resourceId: {from: 'resourceId'},
                        sharedId: {from: 'sharedId', type: 'string'},
                        title: {from: 'title'},
                        allDay: {from: 'allDay', type: 'boolean'},
                        outOfOffice: {from: 'outOfOffice', type: 'boolean'},
                        start: {from: 'start', type: 'date'},
                        end: {from: 'end', type: 'date'},
                        bgColor: {from: 'bgColor', type: 'string'},
                        fgColor: {from: 'fgColor', type: 'string'},
                        location: {from: 'location', type: 'string'},
                        organizer: {from: 'organizer', type: 'boolean'},
                        canInviteGuests: {from: 'canInviteGuests', type: 'boolean'},
                        link: {from: 'link', type: 'string'},
                    }
                }
            },
        });
    }

    _initAPI() {
        this.api = {
            refreshView: (...args) => { this._refreshView(...args); },
            addRow: (data, loadEvents) => {
                const resource = this.resources.add(data);
                if (loadEvents) this.http.listEvents([resource]);
            },
            addRows: (...args) => {
                // Kendo internal: Increase performance by prevent refresh of view, as refreshView is called anyway
                this.kendoScheduler._preventRefresh = true;
                this.resources.addFromList(...args);
                this.kendoScheduler._preventRefresh = false;
            },
            getRow: (...args) => { return this.resources.getById(...args); },
            getRows: (...args) => { return Array.from(this.resources.getAll(...args)); },
            getRowIdFromJsEvent: (...args) => { return this.resources.getIdFromJsEvent(...args); },
            isRowWriteable: (...args) => { return this.resources.isWriteable(...args); },
            updateRowName: (...args) => { this.resources.setName(...args); },
            updateRowColor: (...args) => { this.resources.setColor(...args); },
            removeRow: (...args) => { this.resources.delete(...args); },
            setRowVisibility: (...args) => { this.resources.setVisibilityByIds(...args); },
            hideRow: (...args) => { this.resources.hideById(...args); },
            moveRow: (...args) => {
                const previousOrder = this.resources.getAllSortedByUIOrder().filter(r => r.order !== null).map(r => r.id);
                this.resources.changeDisplayOrder(...args);
                const newOrder = this.resources.getAllSortedByUIOrder().map(r => r.id);

                return { previous: previousOrder, current: newOrder };
            },
            addEvent: (...args) => { this.events.add(...args); },
            addEventFromJsEvent: (...args) => { this.events.addFromJsEvent(...args); },
            addEventFromSearchEvent: (...args) => { this.events.addFromSearchEvent(...args); },
            updateEventColor: (...args) => { this.events.setColor(...args); },
            getEvent: (...args) => { return this.events.getById(...args); },
            getEventIdFromJsEvent: (...args) => { return this.events.getIdFromJsEvent(...args); },
            editEvent: (...args) => { this.events.showEdit(...args); },
            duplicateEvent: (...args) => { this.events.duplicate(...args); },
            deleteEvent: (...args) => { this.events.delete(...args); },
            filterEvents: (...args) => { this.events.filterEvents(...args); },
            generatePdf: (...args) => { this.export.generatePdf(...args); },
            generateExcel: (...args) => { this.export.generateExcel(...args); },
        };
    }

    _initJsEventHandlers() {
        if (!kendo.support.mobileOS) {
            this.kendoScheduler.element.on('click', '.k-event', (...args) => {
                this.handlers.eventShowEdit(...args);
            });
        }
    }

    _refreshView(settings, loadEvents) {
        if (settings && this.timezone !== settings.timezone) {
            this.timezone = settings.timezone;
            this.kendoScheduler.setDataSource(this._buildKendoDataSource());
        }

        this.view.refresh(settings);

        if (loadEvents) {
            this.$timeout(() => {
                this.http.listEvents();
            });
        }
    }

    _contextMenuInit($event, menu, actionType) {
        this.contextMenuInit({$event, menu, actionType});
    }

    _contextMenuSelect($event) {
        this.contextMenuSelect({$event});
    }
}
