import angular from "angular";

const ROLE_WRITEABLE = 'W';
const DIRECTION_UP = 'up';
const DIRECTION_DOWN = 'down';

export default class SchedulerResources {
    constructor(bindings, $window, $mdToast) {
        this.bindings = bindings;
        this.$window = $window;
        this.$mdToast = $mdToast;
    }

    _getDS() {
        return this.bindings.getKendoScheduler().resources[0].dataSource;
    }

    getAll() {
        return this._getDS().data();
    }

    getAllSortedByUIOrder() {
        return this.bindings.getKendoScheduler().resources[0].dataSource.view();
    }

    getById(id) {
        for(let resource of this.getAll()) {
            if (resource.id === id) {
                return resource;
            }
        }

        return undefined;
    }

    getByIds(idSet) {
        const resources = [];

        for(let resource of this.getAll()) {
            if (idSet.has(resource.id)) {
                resources.push(resource);
            }
        }

        return resources;
    }

    getByExtIds(extIdSet) {
        const resources = [];

        for(let resource of this.getAll()) {
            if (extIdSet.has(resource.extId)) {
                resources.push(resource);
            }
        }

        return resources;
    }

    getIdFromJsEvent(jsEvent) {
        const groupHeaderItem = (jsEvent.currentTarget || jsEvent.target).firstElementChild;
        if (groupHeaderItem) {
            return angular.element(groupHeaderItem).attr('data-uid');
        }

        return undefined;
    }

    add(resource) {
        resource.role = ROLE_WRITEABLE;  // Rows are writeable by default
        this._getDS().add(resource);

        return resource;
    }

    addFromList(resources) {
        for (let resource of resources) {
            resource.role = ROLE_WRITEABLE;  // Rows are writeable by default
        }

        this._getDS().data(resources);
        return resources;
    }

    delete(id) {
        const resource = this.getById(id);
        if (resource) {
            this._getDS().remove(resource);
            return true;
        } else{
            return false;
        }
    }

    setName(id, name) {
        const resource = this.getById(id);
        if (resource) {
            resource.set('name', name);
            return true;
        } else{
            return false;
        }
    }

    setColor(id, color) {
        const resource = this.getById(id);
        if (resource) {
            resource.set('color', color);
            return true;
        } else{
            return false;
        }
    }

    hideById(id) {
        let resource = this.getById(id);
        if (resource) {
            resource.visible = false;
            this._applyFilter();
        }
    }

    setVisibilityByIds(ids) {
        let idSet = new Set(ids);

        for(let resource of this.getAll()) {
            resource.visible = idSet.has(resource.id);
        }

        this._applyFilter();
    }

    _applyFilter() {
        this._getDS().filter({
            logic: 'and',
            filters: [{
                field: "visible",
                operator: "eq",
                value: true,
            }]
        });
    }

    changeDisplayOrder(id, dirOrBeforeRowId) {
        /*
         Either pass for "dirOrBeforeRowId":
         - "up" or "down" to move row up or down
         - ID of another row, to insert row before
         - UNDEFINED to move row to the end
         */

        if (id === dirOrBeforeRowId) {
            return; // Cannot move row onto itself
        }

        let resources = this.getAllSortedByUIOrder();
        let order = 0;
        let matchedPos;
        let beforePos;

        // Assign order to all rows
        for(let pos=0; pos < resources.length; pos++) {
            const resource = resources[pos];

            if (resource.id === id) {
                matchedPos = pos;
            } else if (dirOrBeforeRowId && resource.id === dirOrBeforeRowId) {
                beforePos = pos;
                order += 1;  // Leave gap to insert match
            }

            resource.order = order;
            order += 1;
        }

        if (dirOrBeforeRowId === DIRECTION_UP) {
            if (matchedPos > 0) {
                // Move 1 up
                resources[matchedPos - 1].order += 1;
                resources[matchedPos].set('order', matchedPos - 1);
            }
        } else if (dirOrBeforeRowId === DIRECTION_DOWN) {
            if (matchedPos < resources.length-1)
            {
                // Move 1 down
                resources[matchedPos + 1].order -= 1;
                resources[matchedPos].set('order', matchedPos + 1);
            }
        } else if (beforePos !== undefined) {
            // Move before item with "id X"
            resources[matchedPos].set('order', resources[beforePos].order - 1);
        } else {
            // Move to last item in list
            resources[matchedPos].set('order', resources[resources.length-1].order + 1);
        }
    }

    displayOrderCmp(r1, r2) {
        if (r1.order != undefined && r2.order != undefined) {
            return r1.order - r2.order;
        } else if (r1.order != undefined) {
            return -1;
        } else if (r2.order != undefined) {
            return 1;
        } else {
            return r1.name.localeCompare(r2.name);
        }
    }

    isWriteable(id) {
        if (id) {
            const resource = this.getById(id);
            return resource ? resource.role === ROLE_WRITEABLE: false;
        } else {
            return true;
        }
    }

    showReadOnlyWarning(id) {
        let toast = this.$mdToast.simple().hideDelay(6000).action('Help').highlightAction(true);

        if (id) {
            const resource = this.getById(id);
            toast = toast.textContent(`Calendar "${resource.name}" is read only. Ask owner to give you write permissions.`);
        } else {
            toast = toast.textContent('Calendar is read only. Ask owner to give you write permissions.');
        }

        this.$mdToast.show(toast).then(response => {
            if (response === 'ok') {
                this.$window.open('https://support.google.com/calendar/answer/37082#existing', '_blank');
            }
       });
    }

    showOutdatedWarning(id, refreshFn) {
        let toast = this.$mdToast.simple().hideDelay(6000).action('Refresh').highlightAction(true);

        if (id) {
            const resource = this.getById(id);
            toast = toast.textContent(`Events of calendar "${resource.name}" are outdated. Refresh schedule to see newest events.`);
        } else {
            toast = toast.textContent('Calendar events are outdated. Refresh schedule to see newest events.');
        }

        this.$mdToast.show(toast).then(response => {
            if (response === 'ok') {
                refreshFn();
            }
       });
    }

    showCredentialsInvalidWarning(id) {
        let toast = this.$mdToast.simple().hideDelay(20000).action('Re-Login').highlightAction(true);

        if (id) {
            const resource = this.getById(id);
            toast = toast.textContent(`Google Credentials to access calendar "${resource.name}" are invalid. Re-Login to TeamCal and try again.`);
        } else {
            toast = toast.textContent('Google Credentials to access calendar events are invalid. Re-Login to TeamCal and try again.');
        }

        this.$mdToast.show(toast).then(response => {
            if (response === 'ok') {
                /* Force re-login to same page */
                const currentLocation = window.location.pathname;
                window.location.replace(`/login?force=true&redirect=${encodeURIComponent(currentLocation)}`);
            }
        });
    }
}
