import angular from 'angular';

import AutofocusDirective from './autofocus/autofocus.directive.js';
import EditBoxDirective from './editbox/editbox.directive.js';
import RichEditDirective from './richedit/richedit.directive.js';
import ClipboardxDirective from './clipboard/clipboard.directive.js';
import DebounceFactory from './common/debounce.factory';
import EmojiPickerComponent from "./emojipicker/emojipicker.component.js";
import TimeInput from './timeinput/timeinput.component.js';
import TimeSelector from './timeselector/timselector.component.js';
import LogoutButton from './logout/logoutbutton.component.js';
import SchedulerComponent from './scheduler/scheduler.component.js';
import SchedulerContextMenu from './scheduler/scheduler.contextMenu.component.js';
import Plan from './account/plan.component.js';
import AccountService from './account/account.service.js';
import IntroService from './introduction/intro.service.js';
import KendoService from './account/kendo.service.js';
import TimezoneService from './common/timezone.service';
import OAuthService from './oauth/oauth.service';
import SchedulerTooltipService from "./scheduler/scheduler.tooltip.service";
import SchedulerTimelineViewsService from './scheduler/scheduler.timelineviews.service';
import ColorService from './color/color.service';
import ColorSelectorService from './color/colorselector.service';
import LocalStorageService from './storage/storage.service';

export default angular
    .module('componentsModule', [])
    .directive('autofocus', AutofocusDirective)
    .directive('editBox', EditBoxDirective)
    .directive('richEdit', RichEditDirective)
    .directive('clipboard', ClipboardxDirective)
    .component('emojiPicker', EmojiPickerComponent)
    .component('timeInput', TimeInput)
    .component('timeSelector', TimeSelector)
    .component('logoutButton', LogoutButton)
    .component('schedulerContextMenu', SchedulerContextMenu)
    .component('schedulerComponent', SchedulerComponent)
    .component('plan', Plan)
    .service('DebounceFactory', DebounceFactory)
    .service('AccountService', AccountService)
    .service('IntroService', IntroService)
    .service('KendoService', KendoService)
    .service('TimezoneService', TimezoneService)
    .service('OAuthService', OAuthService)
    .service('SchedulerTooltipService', SchedulerTooltipService)
    .service('SchedulerTimelineViewsService', SchedulerTimelineViewsService)
    .service('ColorService', ColorService)
    .service('ColorSelectorService', ColorSelectorService)
    .service('LocalStorageService', LocalStorageService)
    .name;
