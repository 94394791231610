export default /* @ngInject */ function autofocus($timeout) {
    return {
        restrict: "A",
        link: function(scope, element) {
            $timeout(function() {
                element[0].focus();
            }, 400);
        }
    };
}
