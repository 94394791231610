export default /* @ngInject */ function AppConfig($compileProvider, $mdThemingProvider, $locationProvider, $stateProvider, $urlRouterProvider, $qProvider, $httpProvider) {
    $compileProvider.debugInfoEnabled(false);

    $mdThemingProvider.theme('default')
        .primaryPalette('indigo')
        .accentPalette('light-blue');

    $locationProvider.html5Mode(true);
    $urlRouterProvider.otherwise("/");

    $httpProvider.defaults.xsrfCookieName = 'csrftoken';
    $httpProvider.defaults.xsrfHeaderName = 'X-CSRFToken';

    $qProvider.errorOnUnhandledRejections(false);

    $stateProvider.state('base', {
        url: '/',
        abstract: true,
        template: '<ui-view layout-fill />',
        data: {
            defaultPageTitle: 'TeamCal App',
        },
    });
}
